import { Col, Row } from '@tux-meter-ui/components/Grid';
import { TitleTag } from '@tux-meter-ui/components/TitleTag';
import {
  EQuestionItemSubType,
  EQuestionItemType,
  IQuestionOptionChangeParams,
  IQuestionOptionStyle,
  IQuestionResOption,
} from '@tux-meter-ui/interface/survey';
import { getMaxTipsEle, getOptionTypeEle, getTitleByQuestionItem } from '@tux-meter-ui/utils/question-render-helper';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  EValidateErrorType,
  IQuestionItemRef,
  IQuestionItemValidateError,
  IQuestionItemValidateResult,
} from '../interface';
import './index.less';
import { MultipleOptionPerLine, SingleOptionPerLine } from './util';

interface IQuestionOptionProps {
  onChange: (value: IQuestionResOption, oldValue: IQuestionResOption, params: IQuestionOptionChangeParams) => void;
  index?: number;
  value: IQuestionResOption;
}

export type IOptionValidateErr = 'normal' | 'input';

/**
 * 问卷问题-选项题
 * @param {IQuestionOptionProps} props
 * @returns
 */
export const QuestionOption = forwardRef<IQuestionItemRef, IQuestionOptionProps>(({ onChange, index, value }, ref) => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const questionOptionRefs = useRef<(IQuestionItemRef | null)[]>([]);
  const elementId = useMemo(() => `question-num-${index}`, [index]);
  const styleObj = useMemo(() => {
    const { style } = value.model;
    const styleObj: IQuestionOptionStyle = style
      ? JSON.parse(style.replace(/: "/g, ':"'))
      : {
          line_items: '3',
        };
    return styleObj;
  }, [value.model]);

  const ele = useMemo(() => {
    const item = value;
    if (item.type !== EQuestionItemType.Option) {
      return null;
    }
    const { sub_type } = item.model;
    const { line_items, max } = styleObj;
    // 展示1行1个，还是1行多个
    const optionContentEle =
      line_items === '1' ? (
        <div className="multi-container-text">
          {(item.model.options ?? []).map((option, index) => (
            <SingleOptionPerLine
              key={option.id}
              ref={(el) => (questionOptionRefs.current[index] = el)}
              option={option}
              value={item}
              optionStyle={styleObj}
              onChange={(value, oldValue, params) => {
                if (params.validateResult?.result === false) {
                  setErrorMsg(params.validateResult.errors?.[0].message);
                  return;
                }
                onChange(value, oldValue, params);
              }}
            />
          ))}
        </div>
      ) : (
        <div className="multi-container-pop">
          <Row gutter={[8, 8]} align="stretch">
            {(item.model.options ?? []).map((option, index) => (
              <MultipleOptionPerLine
                key={option.id}
                ref={(el) => (questionOptionRefs.current[index] = el)}
                option={option}
                value={item}
                optionStyle={styleObj}
                onChange={(value, oldValue, params) => {
                  if (params.validateResult?.result === false) {
                    setErrorMsg(params.validateResult.errors?.[0].message);
                    return;
                  }
                  onChange(value, oldValue, params);
                }}
              />
            ))}
          </Row>
        </div>
      );
    return (
      <div className="task-question-item task-question-item-option" id={elementId}>
        <div className="title">
          <Row gutter={8}>
            <Col>{getTitleByQuestionItem(item.model, index)}</Col>
            <Col>{getOptionTypeEle(item.model)}</Col>
            {errorMsg && (
              <Col>
                <TitleTag
                  duration={2000}
                  onClose={() => {
                    setErrorMsg(undefined);
                  }}
                >
                  {errorMsg}
                </TitleTag>
              </Col>
            )}
          </Row>
        </div>
        {sub_type === EQuestionItemSubType.RADIO && !item.model.required
          ? null
          : getMaxTipsEle(max, item.model.required)}
        {optionContentEle}
      </div>
    );
  }, [value, elementId, index, styleObj, onChange, errorMsg]);

  function validate(): IQuestionItemValidateResult {
    const childValidateErrRes = questionOptionRefs.current.reduce((res: IQuestionItemValidateResult[], itemRef) => {
      if (itemRef?.validate) {
        const errRes = itemRef.validate();
        if (!errRes.result) {
          return [...res, errRes];
        }
      }
      return res;
    }, []);
    // 子组件验证失败，返回验证失败信息
    if (childValidateErrRes.length > 0 && !childValidateErrRes[0].result) {
      return childValidateErrRes[0];
    }
    const { value: resValue, model } = value;
    const res: IQuestionItemValidateResult = {
      result: false,
      errors: [],
    };
    const title = index !== undefined ? `问题${index + 1}` : `问题：${model.title}`;
    if (resValue.length === 0) {
      const errorModel: IQuestionItemValidateError = {
        message: `${title}，尚未选择，请勾选后提交`,
        errorType: EValidateErrorType.QUESTION,
        element: document.getElementById(elementId),
      };
      res.errors.push(errorModel);
      return res;
    }
    res.result = true;
    return res;
  }

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return <div>{ele}</div>;
});
