import { ISurveyModel } from '@tux-meter-ui/h5/interface/survey';
import { getHtmlFragmentsByString } from '@tux-meter-ui/utils/question-render-helper';
import { useMemo } from 'react';
import './index.less';

interface IProps {
  legacy: ISurveyModel;
}

/**
 * 问卷引导内容
 */
export function SurveyGuideContent(props: IProps) {
  const { legacy } = props;

  const title = useMemo(() => legacy.title, [legacy]);
  const guideEles = useMemo(() => {
    const text = legacy.guide_config?.guide_text ?? '';
    return getHtmlFragmentsByString(text);
  }, [legacy]);

  return (
    <div className="survey-guide-content">
      <div className="title">{title}</div>
      <div className="content">{guideEles}</div>
    </div>
  );
}
