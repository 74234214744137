import { SurveyGuideContent } from '@tux-meter-ui/h5/components/SurveyGuideContent';
import { ISurveyModel } from '@tux-meter-ui/h5/interface/survey';
import { Button } from 'tdesign-react';
import styles from './index.module.less';

interface IProps {
  legacy: ISurveyModel;
  onSubmit?: () => void;
}

/**
 * 问卷提交成功页
 */
export function SurveyGuideView(props: IProps) {
  const { legacy, onSubmit } = props;

  return (
    <div className={styles['survey-guide-view']}>
      <div className={styles.container}>
        <div className='flex-columns h-full'>
          <div className='flex-1 overflow-y-auto'>
            <div className={styles['content-panel']}>
              <SurveyGuideContent legacy={legacy} />
            </div>
          </div>
          <div className='flex-none'>
            <div className={styles['btn-panel']}>
              <Button
                className={styles.btn}
                size='large'
                onClick={() => {
                  onSubmit?.();
                }}
              >
                <span className={styles.text}>开始答题</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
