import { IQuestionChangeBaseParams, IQuestionResTime, IQuestionTimeStyle } from '@tux-meter-ui/interface/survey';
import { getTitleByQuestionItem } from '@tux-meter-ui/utils/question-render-helper';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { EValidateErrorType, IQuestionItemRef, IQuestionItemValidateResult } from '../interface';
import { ITimePickerFields, MobileDatePicker } from '../MobileDatePicker';
import './index.less';

interface IQuestionTimeProps {
  onChange: (value: IQuestionResTime, oldValue: IQuestionResTime, params: IQuestionChangeBaseParams) => void;
  index?: number;
  value: IQuestionResTime;
}

/**
 * 问卷问题-时间选择题
 * @param {IQuestionOptionImageProps} props
 * @returns
 */
export const QuestionTime = forwardRef<IQuestionItemRef, IQuestionTimeProps>(({ onChange, index, value }, ref) => {
  const elementId = useMemo(() => `option-time-doc-id-${index}`, [index]);
  const pickerRef = useRef<HTMLDivElement>(null);

  const styleObj = useMemo(() => {
    const { style } = value.model;
    const styleObj: IQuestionTimeStyle = style
      ? JSON.parse(style.replace(/: "/g, ':"'))
      : {
          time_style: 'time',
        };
    return styleObj;
  }, [value.model]);

  function validate(): IQuestionItemValidateResult {
    const { value: resValue } = value;
    const res: IQuestionItemValidateResult = {
      result: false,
      errors: [],
    };
    if (!resValue.time) {
      res.errors.push({
        message: styleObj.time_style === 'date' ? '请选择日期' : '请选择日期与时间',
        errorType: EValidateErrorType.TIP,
        element: pickerRef.current,
      });
      return res;
    }
    res.result = true;
    return res;
  }

  const timePickerFields: ITimePickerFields[] = useMemo(() => {
    switch (styleObj.time_style) {
      case 'minutes':
        return ['hour', 'minute'];
      case 'time':
        return ['hour', 'minute', 'second'];
      default:
        return [];
    }
  }, [styleObj.time_style]);

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <div className="task-question-item task-question-item-time" id={elementId}>
      {value.model.title && (
        <>
          <div>{getTitleByQuestionItem(value.model, index)}</div>
        </>
      )}
      <div className="mt-12px" ref={pickerRef}>
        <MobileDatePicker
          value={value.value.time}
          placeholder={styleObj.time_style === 'date' ? '请选择日期' : '请选择日期与时间'}
          timePickerFields={timePickerFields}
          startTime={styleObj.from_time}
          endTime={styleObj.to_time}
          onConfirm={(time) => {
            // 获取用户时区偏移量（以分钟为单位）
            // 正值表示本地时间落后于 UTC，负值表示本地时间领先于 UTC
            const timezoneOffsetMinutes = new Date().getTimezoneOffset();
            // utc_offset存储的是时区小时整数，东区为正，西区为负数，所以这里除以-60
            const utc_offset = Math.floor(timezoneOffsetMinutes / -60);
            const res: IQuestionResTime = {
              type: value.model.type,
              model: value.model,
              value: {
                time: time ?? '',
                utc_offset,
              },
            };
            onChange(res, value, { isDisplayChange: false });
          }}
        />
      </div>
    </div>
  );
});
