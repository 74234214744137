import {
  ESurveyCallbackFn,
  getPersonalizedLegacy,
  IGetPersonalizedLegacyArgs,
} from '@tux-meter-ui/h5/api/survey';
import ImageError from '@tux-meter-ui/h5/assets/error.png';
import CusLoading from '@tux-meter-ui/h5/components/CusLoading';
import { Col, Row } from '@tux-meter-ui/h5/components/Grid';
import { SurveyErrorBoundary } from '@tux-meter-ui/h5/components/SurveyErrorBoundary';
import { EQuestionItemType, ISurveyModel } from '@tux-meter-ui/h5/interface/survey';
import { beacon } from '@tux-meter-ui/h5/utils/beacon';
import { HtmlHelper } from '@tux-meter-ui/h5/utils/html-helper';
import { useRoute } from '@tux-meter-ui/h5/utils/router-helper';
import { useSurveyQueryArgs } from '@tux-meter-ui/utils/surveyQueryArgs';
import { useDebounceFn } from 'ahooks';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from 'tdesign-icons-react';
import { Empty } from 'tdesign-react';
import { SurveyGuideView } from '../SurveyGuideView';
import styles from './index.module.less';

interface IRenderSurveyViewArgs {
  legacy: ISurveyModel;
  q36?: string;
}

interface IProps {
  renderSurveyView: (props: IRenderSurveyViewArgs) => JSX.Element;
}

/**
 * 问卷前置请求视图，用于解耦操作（增加引导页）
 * @returns
 */
export function PreFetchView({ renderSurveyView }: IProps) {
  const {
    q36,
    q36Init,
    appId,
    surveyId,
    surveyType,
    zoneId,
    userId,
    uuid,
    cbFns,
  } = useSurveyQueryArgs();
  const route = useRoute();
  // 是否需要引导页
  const [needGuidePage, setNeedGuidePage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [legacy, setLegacy] = useState<ISurveyModel | undefined>();
  const title = useMemo(() => {
    const title = legacy?.title ?? '';
    if (_.isString(title) && title.length > 0) {
      return title;
    }
    return '用户调研';
  }, [legacy?.title]);
  useEffect(() => {
    HtmlHelper.setTitle(title);
  }, [title]);
  const personalizedLegacyArgs = useMemo(() => {
    if (surveyId && q36Init) {
      return {
        zone_id: zoneId,
        survey_id: surveyId,
        user_id: userId ?? '',
        business: appId ?? 'qb',
        is_white: route.query.is_white === '1',
        is_permanent: route.query.is_permanent === '1',
      };
    }
    return undefined;
  }, [route.query, surveyId, appId, q36Init, zoneId, userId]);
  const fetchFn = useCallback(
    async (personalizedLegacyArgs: IGetPersonalizedLegacyArgs) => {
      if (personalizedLegacyArgs) {
        try {
          setLoading(true);
          const startTime = performance.now();
          const res = await getPersonalizedLegacy(personalizedLegacyArgs, appId);
          const endTime = performance.now();
          const elapsedTime = endTime - startTime;
          // 上报请求耗时
          beacon.onDirectUserAction('tux_h5_query_elapsed_time', {
            tux_zone_id: zoneId,
            env: window.ENV_CONSTANT.CLINT_ENV,
            iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
            survey_type: surveyType,
            server_type: 'H5',
            elapsed_time: elapsedTime,
            ...personalizedLegacyArgs,
            query_res: JSON.stringify(res),
          });
          if (res?.pages.length && res?.pages.length > 0) {
            res.pages = res.pages.map((page) => {
              const questions = page?.questions ?? [];
              const newQuestions = questions.map((question) => {
                // 旧的数据没有required时，默认必填
                if (
                  (question.type === EQuestionItemType.Option || question.type === EQuestionItemType.Star) &&
                  question.required === undefined
                ) {
                  question.required = true;
                }
                return question;
              });
              return { ...page, questions: newQuestions };
            });
          }
          setNeedGuidePage(!!res?.guide_config?.guide_page_switch);
          setLegacy(res);
          setLoading(false);
        } catch (err) {
          console.log('err:', err);
          setLegacy(undefined);
          setLoading(false);
        }
      }
    },
    [appId, surveyType, zoneId],
  );
  useEffect(() => {
    if (personalizedLegacyArgs) {
      fetchFn(personalizedLegacyArgs);
    }
  }, [personalizedLegacyArgs, fetchFn]);
  const reportErrorEvent = useCallback(
    (msg: string, body?: string) => {
      beacon.onDirectUserAction('tux_h5_submit_error', {
        tux_zone_id: zoneId,
        router: window.location.href,
        env: window.ENV_CONSTANT.CLINT_ENV,
        iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
        tux_uid: userId,
        tux_uuid: uuid || 'test',
        survey_id: surveyId,
        survey_type: surveyType,
        server_type: 'H5',
        msg,
        app_id: appId,
        body: body ?? '',
      });
    },
    [appId, surveyId, surveyType, userId, uuid, zoneId],
  );
  const cancelBtnEle = useMemo(() => {
    const index = cbFns.findIndex((c) => c === ESurveyCallbackFn.CANCEL);
    if (index === -1) {
      return null;
    }
    const isFrameClass = window !== window.top ? 'is-iframe' : '';
    return (
      <div className={`close ${isFrameClass}`}>
        <Icon
          name="close"
          className="fs-26px"
          onClick={() => {
            if (window.parent.postMessage) {
              window.parent.postMessage(ESurveyCallbackFn.CANCEL, '*');
              console.log(`触发 ${ESurveyCallbackFn.CANCEL}`);
              return;
            }
            reportErrorEvent(
              `触发postMessage ${ESurveyCallbackFn.CANCEL} 失败，该环境不存在window.parent.postMessage函数`,
            );
          }}
        />
      </div>
    );
  }, [cbFns, reportErrorEvent]);
  // 是否需要显示引导页
  const showGuidePage = useMemo(() => !!legacy?.guide_config?.guide_page_switch && needGuidePage, [legacy?.guide_config?.guide_page_switch, needGuidePage]);
  const h5LoadedReport = useCallback(() => {
    // 页面加载完毕执行上报
    beacon.onDirectUserAction('tux_h5_loaded', {
      tux_zone_id: zoneId,
      full_path: window.location.href,
      env: window.ENV_CONSTANT.CLINT_ENV,
      iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
      tux_uid: userId,
      tux_uuid: uuid || 'test',
      survey_id: surveyId,
      app_id: appId,
      route_map_info: JSON.stringify(route),
    });
  }, [appId, route, surveyId, uuid, userId, zoneId]);
  const { run: h5LoadedReportDebounceFn } = useDebounceFn(() => {
    h5LoadedReport();
  }, { wait: 200 });
  useEffect(() => {
    h5LoadedReportDebounceFn();
  }, [h5LoadedReportDebounceFn, h5LoadedReport]);
  if (loading) {
    return (
      <div
        style={{
          height: 'calc(100vh)',
          width: 'calc(100vw)',
        }}
      >
        <Row className="w-100-p h-100-p" justify="center" align="middle" gutter={8}>
          <Col>
            <CusLoading />
          </Col>
          <Col className="c-pri-6 fs-16px fw-5">加载问卷内容中...</Col>
        </Row>
      </div>
    );
  }
  if (!legacy) {
    return (
      <div className="container">
        {cancelBtnEle}
        <div style={{ height: 'calc(80vh)' }}>
          <Row className="w-100-p h-100-p" justify="center" align="middle" gutter={8}>
            <Col>
              <Empty
                image={<img src={ImageError} width="196" />}
                size="large"
                title="网络出错，请稍后重试"
                type="fail"
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return <div className={styles['pre-fetch-view']}>
    {
      showGuidePage && <SurveyGuideView
        legacy={legacy}
        onSubmit={() => {
          setNeedGuidePage(false);
        }}
      />
    }
    <div style={{ display: !showGuidePage ? 'block' : 'none' }}>
      {renderSurveyView({ legacy, q36 })}
    </div>
    <SurveyErrorBoundary />
  </div>;
}
