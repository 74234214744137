import { queryDimensions } from '@tux-meter-ui/h5/api/project-request';
import { handlePersonalRecommendQuestion, initializeQuestions } from '@tux-meter-ui/h5/api/survey';
import ImageError from '@tux-meter-ui/h5/assets/error.png';
import { IFileInfo, getFileById } from '@tux-meter-ui/h5/bus/use-magic-conch-file';
import CusLoading from '@tux-meter-ui/h5/components/CusLoading';
import { ErrorBoundary } from '@tux-meter-ui/h5/components/ErrorBoundary';
import { Col, Row } from '@tux-meter-ui/h5/components/Grid';
import QuestionPreviewer from '@tux-meter-ui/h5/components/QuestionPreviewer';
import { IDimensionModel } from '@tux-meter-ui/h5/interface/project';
import { EMetricType, EQuestionItemType, IQuestionItem, ISurveyModel, ISurveyPage } from '@tux-meter-ui/h5/interface/survey';
import { useRoute } from '@tux-meter-ui/h5/utils/router-helper';
import { convert2SurveyQuestions } from '@tux-meter-ui/h5/utils/survey-helper';
import _ from 'lodash';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { Empty, MessagePlugin } from 'tdesign-react';
import { SurveyGuideView } from '../SurveyGuideView';

function getLegacyByFileString(dimensions: IDimensionModel[], str?: string) {
  if (str) {
    const { questions, pages, legacy }: {
      questions?: IQuestionItem[];
      pages?: ISurveyPage[];
      legacy?: ISurveyModel;
    } = JSON.parse(str);
    const newPages: ISurveyPage[] = pages ? pages : [
      {
        id: '1',
        is_deleted: false,
        questions: questions ?? []
      }
    ];
    const newLegacy: ISurveyModel = {
      last_modified_by: '',
      index_group_id: '',
      metric: EMetricType.CSAT,
      smart_mode: true,
      pages: newPages,
      title: '',
      create_time: '',
      update_time: '',
      ...legacy,
    };
    newLegacy.pages = newLegacy.pages
      .map((page) => {
        const { questions } = page;
        const newQuestions = getPersonalRecommendQuestion(questions, dimensions);
        return { ...page, questions: newQuestions };
      })
      .filter((c) => !c.is_deleted && c.questions.length > 0);
    return newLegacy;
  }
  return undefined;
}


function getPersonalRecommendQuestion(questions: IQuestionItem[], dimensions: IDimensionModel[]) {
  // 预览的数据包含被软删除的数据
  const newQuestions = questions.map((c) => {
    if (c.type === EQuestionItemType.Star || c.type === EQuestionItemType.Option) {
      const options = c.options.filter((c) => c.is_deleted !== true);
      return { ...c, options };
    }
    if (c.type === EQuestionItemType.Matrix) {
      const options = c.options.filter((c) => c.is_deleted !== true);
      return { ...c, options };
    }
    return c;
  });
  handlePersonalRecommendQuestion(newQuestions, dimensions);
  console.log('newQuestions', newQuestions);
  const arr = initializeQuestions(newQuestions);
  console.log('newQuestions1', arr);
  console.log('convert2SurveyQuestions(arr):', convert2SurveyQuestions(arr));
  // 显示的问卷问题需要单独根据各种问题类型做预处理操作
  return convert2SurveyQuestions(arr);
}

/**
 * 问卷预览页面
 */
function SurveyPreview() {
  const route = useRoute();
  const [legacy, setLegacy] = useState<ISurveyModel | undefined>();
  // 是否需要引导页
  const [needGuidePage, setNeedGuidePage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [q36, setQ36] = useState<string | undefined>();

  const fileInfoId = useMemo(() => {
    return route.query.fileInfoId as string;
  }, [route.query.fileInfoId]);

  const fetchFn = useCallback(async () => {
    if (fileInfoId) {
      setLoading(true);
      try {
        const model: IFileInfo | undefined = await getFileById(fileInfoId);
        const { app_id }: { app_id: string } = JSON.parse(model!.content);
        const dimensionModle = await queryDimensions(app_id);
        const legacy = getLegacyByFileString(dimensionModle.data, model?.content);
        setNeedGuidePage(!!legacy?.guide_config?.guide_page_switch);
        setLegacy(legacy);
      } catch (err) {
        console.log('err:', err);
      }
      setLoading(false);
    }
  }, [fileInfoId]);

  useEffect(() => {
    fetchFn();
  }, [fetchFn]);

  useEffect(() => { }, []);

  // 是否需要显示引导页
  const showGuidePage = useMemo(() => legacy?.guide_config?.guide_page_switch && needGuidePage, [legacy, needGuidePage]);

  const uuid = useMemo(() => {
    const params = route.params;
    const taskinfo_id = params.taskinfo_id;
    // 如果有传递task信息的id过来，则使用uuid拼接
    if (taskinfo_id && q36) {
      // 补充id为6位
      const left = _.padStart(taskinfo_id, 6, '0');
      // 拼接uuid
      // 文档参考https://iwiki.woa.com/pages/viewpage.action?pageId=1689393596
      return `${left}-${q36}`;
    }
    return params.uuid || '';
  }, [route.params, q36]);

  useEffect(() => {
    if (uuid) {
      setTimeout(() => {
        MessagePlugin.success(`当前uuid为: ${uuid}`);
      }, 3000);
    }
  }, [uuid]);

  useEffect(() => {
    window.browser?.app?.getBrowserParam(
      (params) => {
        const { qimei36 } = params;
        setQ36(qimei36);
        MessagePlugin.success(`当前q36为: ${qimei36}`);
      },
      (error) => {
        MessagePlugin.error(JSON.stringify(error));
      },
    );
  }, []);

  if (loading) {
    return (
      <div style={{ height: 'calc(100vh)', width: 'calc(100vw)' }}>
        <Row className="w-100-p h-100-p" justify="center" align="middle" gutter={8}>
          <Col>
            <CusLoading />
          </Col>
          <Col className="c-pri-6 fs-16px fw-5">加载问卷内容中...</Col>
        </Row>
      </div>
    );
  }

  if (!legacy) {
    return <div style={{ height: 'calc(100vh)' }}>
      <Row className="w-100-p h-100-p" justify="center" align="middle" gutter={8}>
        <Col>
          <Empty
            image={<img src={ImageError} width="196" />}
            size="large"
            title="网络出错，请稍后重试"
            type="fail"
          />
        </Col>
      </Row>
    </div>;
  }

  return (
    <div
      style={{
        height: 'calc(100vh)',
        width: 'calc(100vw)',
      }}
    >
      {
        showGuidePage && <SurveyGuideView
          legacy={legacy}
          onSubmit={() => {
            setNeedGuidePage(false);
          }}
        />
      }
      <div style={{ display: showGuidePage ? 'none' : 'block' }} className='h-full w-full'>
        <ErrorBoundary>
          <QuestionPreviewer legacy={legacy} />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default SurveyPreview;
