import { IQuestionItemValidateResult } from '@tux-meter-ui/components/QuestionItem/interface';

export enum EMetricType {
  // 满意度问卷
  CSAT = 0,
  // 推荐度问卷
  NPS = 1,
  // 费力度问卷
  CES = 2,
}

/**
 * 拖拽组件
 */
export interface ISortableChangeEvent {
  /**
   * 移动变换位置时
   */
  moved?: {
    element: IQuestionItem;
    newIndex: number;
    oldIndex: number;
  };
  /**
   * 左侧拖动组件到右侧新增时
   */
  added?: {
    element: IQuestionItem[];
    newIndex: number;
  };
}

export enum EQuestionItemType {
  /**
   * 量表题
   */
  Star = 'star',
  /**
   * 文本框
   */
  Text = 'text',
  /**
   * 多选模式
   */
  Option = 'option',
  /**
   * 时间选择
   */
  Time = 'time',
  /**
   * 敏感信息输入框
   */
  SensitiveInput = 'sensitive_input',
  /**
   * 插入图片
   */
  Image = 'image',
  /**
   * 矩阵题
   */
  Matrix = 'matrix',
}

/**
 * 问卷子类型
 */
export enum EQuestionItemSubType {
  /**
   * 评价打分
   */
  STAR_STAR = 'star_star',
  /**
   * 量表NPS
   */
  STAR_TEXT = 'star_text',
  /**
   * 矩阵单选
   */
  MATRIX_RADIO = 'matrix_radio',
  /**
   * 矩阵多选
   */
  MATRIX_CHECKBOX = 'matrix_checkbox',
  /**
   * 单选
   */
  RADIO = 'radio',
  /**
   * 多选
   */
  CHECKBOX = 'checkbox',
  /**
   * 图片单选
   */
  IMAGE_RADIO = 'image_radio',
  /**
   * 图片多选
   */
  IMAGE_CHECKBOX = 'image_checkbox',
  /**
   * 具体到秒的时间选择
   */
  TIME_OPTION = 'time_option',
  /**
   * 单行文本
   */
  TEXT = 'text',
  /**
   * 多行文本
   */
  TEXTAREA = 'textarea',
  /**
   * 敏感题
   */
  SENSITIVE_INPUT = 'sensitive_input',
}

/**
 * 指标正向负向标记
 */
export enum EQuestionOptionTendency {
  /**
   * 正常
   */
  NEUTRAL = 0,
  /**
   * 负向
   */
  NEGATIVE = 1,
  /**
   * 正向
   */
  POSITIVE = 2,
}

export interface IBlankConfig {
  /**
   * 是否必填
   */
  is_required: boolean;
  /**
   * 最大长度（如果没有限制长度的情况，max_len为0）
   */
  max_len: number;
  /**
   * 对应的placeholder
   */
  tip: string;
}

export interface IQuestionOptionItem {
  id: string;
  text: string;
  exclusive?: boolean;
  goto?: string;
  /**
   * 用于关联下一题
   */
  displays: string[];
  // 是否固定出现
  is_fixed: boolean;
  tendency: EQuestionOptionTendency;
  index_id: string;
  parent_index_id?: string;
  // 选项带图片的选择题
  image: string;
  is_deleted?: boolean;
  /**
   * 命中的维度标签
   */
  dim?: string;
  /**
   * 是否需要填空
   */
  add_blank: boolean;
  /**
   * 填空的配置信息
   */
  blank_config?: null | IBlankConfig;
  /**
   * 选项后方下划线填写后的值
   */
  other_text?: string;
}

export interface IQuestionStarStyle {
  star_question_render_style: 'text' | 'emoji' | 'star';
  /**
   * 选项描述的摆放定位(option_text_placement为undefined时默认为上方top)
   */
  option_text_placement?: 'top' | 'bottom';
}

export interface IRichNodeAttr {
  key: string;
  value: string;
}

export interface IRichNodeStyle {
  key: string;
  value: string;
}

/**
 * 富文本
 */
export interface IRichNode {
  tag: string;
  /**
   * 是否闭合标签，比如<br />
   */
  voidTag?: boolean;
  attr?: IRichNodeAttr[];
  style?: IRichNodeStyle[];
  content?: string;
  children?: IRichNode[];
}

/**
 * 题干：量表题
 */
export interface IQuestionStar extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.Star;
  sub_type: EQuestionItemSubType.STAR_STAR | EQuestionItemSubType.STAR_TEXT;
  style: string;
  title: string;
  /**
   * 富文本标题
   */
  rich_text_title?: string;
  description: string;
  required?: boolean;
  options: IQuestionOptionItem[];
  index_id: string;
  parent_index_id?: string;
  is_deleted?: boolean;
}

/**
 * 横纵比例
 */
export enum EAspectRatio {
  ONE_TO_ONE = '1:1',
  FOUR_TO_THREE = '4:3',
  SIXTEEN_TO_NINE = '16:9',
  THREE_TO_FOUR = '3:4',
  NINE_TO_SIXTEEN = '9:16',
}

export interface IQuestionOptionStyle {
  line_items: string;
  max?: string;
  /**
   * 是否互斥
   */
  multiple?: boolean;
  /**
   * 图片比例
   */
  image_aspect_ratio?: EAspectRatio;
}

/**
 * 题干：多选框
 */
export interface IQuestionOption extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.Option;
  sub_type:
    | EQuestionItemSubType.RADIO
    | EQuestionItemSubType.CHECKBOX
    | EQuestionItemSubType.IMAGE_RADIO
    | EQuestionItemSubType.IMAGE_CHECKBOX;
  style: string;
  title: string;
  rich_text_title?: string;
  description: string;
  required?: boolean;
  options: IQuestionOptionItem[];
  // 为option时，子元素是否随机展示
  is_random?: boolean;
  is_deleted?: boolean;
  /**
   * 最大可展现选项数量（默认为9，最小为2）
   */
  option_max_cnt: string;
}

/**
 * 时间选择题样式
 */
export interface IQuestionTimeStyle {
  /**
   * 时间类型
   * time格式为 YYYY-MM-DD HH:mm:ss
   * minutes格式为 YYYY-MM-DD HH:mm
   * date格式为 YYYY-MM-DD
   */
  time_style: 'time' | 'date' | 'minutes';
  /**
   * 开始时间，格式不会根据 time_style 变化，始终为 YYYY-MM-DD HH:mm:ss
   */
  from_time?: string;
  /**
   * 结束时间，格式不会根据 time_style 变化，始终为 YYYY-MM-DD HH:mm:ss
   */
  to_time?: string;
}

/**
 * 时间选择题填答附加结果
 */
export interface IQuestionTimePayload {
  /**
   * 填答选择的时间
   */
  time: string;
  /**
   * 当前用户所在UTC时区
   */
  utc_offset: number;
}

/**
 * 时间选择题
 */
export interface IQuestionTime extends IQuestionItemBase<IQuestionTimePayload> {
  id: string;
  type: EQuestionItemType.Time;
  sub_type: EQuestionItemSubType.TIME_OPTION;
  style: string;
  title: string;
  rich_text_title?: string;
  description: string;
  required?: boolean;
  is_deleted?: boolean;
}

export interface IQuestionSubTitle {
  /**
   * 行标题id
   */
  id: string;
  /**
   * 行标题
   */
  text: string;
  /**
   * 是否已删除，用于更新接口
   */
  is_deleted: boolean;
}

/**
 * 矩阵题展示样式
 */
export enum EQuestionMatrixDisplayStyle {
  /**
   * 列表形式
   */
  MATRIX = 'matrix',
  /**
   * 选择题形式
   */
  OPTION = 'option',
}

/**
 * 矩阵特殊样式
 */
export interface IQuestionMatrixStyle {
  line_items?: string;
  display_style: EQuestionMatrixDisplayStyle;
  max?: string;
}

/**
 * 高级题型
 */
export enum EAdvancedType {
  EMPTY = '',
  KANO = 'kano',
}

/**
 * 题干：矩阵题
 */
export interface IQuestionMatrix extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.Matrix;
  sub_type: EQuestionItemSubType.MATRIX_RADIO | EQuestionItemSubType.MATRIX_CHECKBOX;
  sub_titles: IQuestionSubTitle[];
  style: string;
  title: string;
  rich_text_title?: string;
  description: string;
  required?: boolean;
  options: IQuestionOptionItem[];
  // 为option时，子元素是否随机展示
  is_random?: boolean;
  is_deleted?: boolean;
  /**
   * 引用其他多选题的题目id，最后保存时根据这个id匹配多选题并使用它的options填充至sub_titles
   */
  refer?: string;
}

/**
 * 题干：文本框-开放题选项
 */
export interface IQuestionText extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.Text;
  sub_type: EQuestionItemSubType.TEXT | EQuestionItemSubType.TEXTAREA;
  title: string;
  rich_text_title?: string;
  description: string;
  required?: boolean;
  is_deleted?: boolean;
  // index_id: string;
}

/**
 * 敏感信息输入框定制化面板参数
 */
export interface IQuestionSensitiveInputStyle {
  // 是否需要填答者协议和腾讯问卷隐私政策1为是，0为否
  license: number;
}

export interface IQuestionSensitiveInput extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.SensitiveInput;
  sub_type: EQuestionItemSubType.SENSITIVE_INPUT;
  style: string;
  // 标题
  title: string;
  rich_text_title?: string;
  // 描述（副标题）
  description: string;
  // 文本框placeholder
  placeholder: string;
  required?: boolean;
  is_deleted?: boolean;
}

/**
 * 千人千面标题配置
 */
export interface IDynamicQuestionTitleExp {
  replaced_title_content: string;
  dimension_id: number;
  dimension_group_ids: string[];
  backup_dimension_group_ids: string[];
}

/**
 * 千人千面选项配置
 */
export interface IDynamicOptionExp {
  option_ids: string[];
  dimension_id: number;
  dimension_group_ids: string[];
  backup_dimension_group_ids: string[];
}

/**
 * 问题Base类
 */
export interface IQuestionItemBase<TPayload extends Record<string, any> = Record<string, any>> {
  /**
   * 高级题型
   */
  advanced_type?: EAdvancedType;
  /**
   * 高级题型uuid，用于联动等操作
   */
  advanced_id?: string;
  /**
   * 正负向（高级题型kano时，拖拽一条kano会生成两道题，一道是正向一道反向）
   */
  tendency?: EQuestionOptionTendency;

  rich_text_title?: string;
  /**
   * 命中的维度标签
   */
  dim?: string;

  dynamic_question_title_exp?: IDynamicQuestionTitleExp;
  dynamic_option_exp?: IDynamicOptionExp;
  /**
   * 用于组件存储的临时数据，按需决定是否需要填入填答数据中
   */
  submit_payload?: TPayload;
}

/**
 * 题干对象（可能为量表题、选项题或者开放题）
 */
export type IQuestionItem =
  | IQuestionStar
  | IQuestionOption
  | IQuestionTime
  | IQuestionMatrix
  | IQuestionText
  | IQuestionSensitiveInput;

export interface IQuestionResStar {
  type: EQuestionItemType.Star;
  model: IQuestionStar;
  value: IQuestionOptionItem[];
}

export interface IQuestionResOption {
  type: EQuestionItemType.Option;
  model: IQuestionOption;
  value: IQuestionOptionItem[];
}

export interface IQuestionResTime {
  type: EQuestionItemType.Time;
  model: IQuestionTime;
  value: IQuestionTimePayload;
}

/**
 * 选项选中状态
 */
export enum EQuestionOptionStatus {
  /**
   * CHOOSED 已选择
   */
  CHOOSED = 0,
  /**
   * UNCHOOSED 未选择
   */
  UNCHOOSED = 1,
  /**
   * UNSHOWED 未显示
   */
  UNSHOWED = 2,
}

/**
 * 问卷问题每个选项保存结果
 */
export interface IQuestionResOptionItem {
  id: string;
  text: string;
  status: EQuestionOptionStatus;
  dim?: string;
}

/**
 * 答题保存结果：矩阵每行
 */
export interface IQuestionMatrixResRow {
  id: string;
  text: string;
  options: IQuestionResOptionItem[];
}

export interface IQuestionResMatrix {
  type: EQuestionItemType.Matrix;
  model: IQuestionMatrix;
  value: IQuestionMatrixResRow[];
}

export interface IQuestionResText {
  type: EQuestionItemType.Text;
  model: IQuestionText;
  value: string;
}

export interface IQuestionResSensitiveInput {
  type: EQuestionItemType.SensitiveInput;
  model: IQuestionSensitiveInput;
  value: string;
  confirm: boolean;
}

export type IQuestionResItem =
  | IQuestionResStar
  | IQuestionResOption
  | IQuestionResTime
  | IQuestionResMatrix
  | IQuestionResText
  | IQuestionResSensitiveInput;

/**
 * 问题onChange时参数变更
 */
export interface IQuestionChangeBaseParams {
  /**
   * 是否需要变更展示的题目
   */
  isDisplayChange: boolean;
  validateResult?: IQuestionItemValidateResult;
}

/**
 * 问题onChange时参数变更：量表题
 */
export interface IQuestionStarChangeParams extends IQuestionChangeBaseParams {
  optionValue?: IQuestionOptionItem;
}

/**
 * 问题onChange时参数变更：选择题
 */
export type IQuestionOptionChangeParams = IQuestionStarChangeParams;

/**
 * 问题onChange时参数变更：图片选择题
 */
export type IQuestionOptionImageChangeParams = IQuestionStarChangeParams;

/**
 * 问题onChange时参数变更：矩阵题
 */
export type IQuestionMatrixChangeParams = IQuestionChangeBaseParams;

/**
 * 问题onChange时参数变更
 */
export type IQuestionChangeParams =
  | IQuestionChangeBaseParams
  | IQuestionStarChangeParams
  | IQuestionOptionChangeParams
  | IQuestionOptionImageChangeParams
  | IQuestionMatrixChangeParams;

/**
 * 问卷分页对象
 */
export interface ISurveyPage {
  id: string;
  questions: IQuestionItem[];
  is_deleted: boolean;
}

/**
 * 引导页配置
 */
export interface IGuideConfig {
  /**
   * 是否开启引导页，默认为false
   */
  guide_page_switch: boolean;
  /**
   * 是否展示标题和引导语
   */
  title_switch: boolean;
  /**
   * 引导语文本(JSON字符串)
   * 可通过JSON.parse为 IRichNode[] 
   */
  guide_text: string;
}

export interface ISurveyModel {
  title: string;
  /**
   * 引导页配置
   */
  guide_config?: IGuideConfig;
  create_time: string;
  update_time: string;
  last_modified_by: string;
  index_group_id: string;
  project_id?: number;
  /**
   * 衡量标准，指标组类型，如csat、nps、ces(默认之前创建没有metric则为csat)
   */
  metric: EMetricType;
  /**
   * 是否开启智能投放模式（默认关闭）
   */
  smart_mode: boolean;
  /**
   * 腾讯问卷ID
   */
  tencent_survey_id?: string;
  /**
   * 问卷分页集合
   */
  pages: ISurveyPage[];
}

export enum ESubmitSurveyStatus {
  Selected = 0,
  /**
   * 未勾选option需要标记为1
   * 提交问卷时回传给后端 https://tapd.woa.com/10046451/prong/stories/view/1010046451887897937
   */
  Unselected = 1,
}

export interface ISubmitSuveryQuestionDefault {
  id: string;
}

export interface ISubmitSuveryQuestionBase {
  id: string;
  type: EQuestionItemType;
  sub_type: EQuestionItemSubType;
  /**
   * 命中的维度标签
   */
  dim?: string;
}

export interface ISubmitSurveyQuestionText extends ISubmitSuveryQuestionBase {
  text: string;
}

export interface ISubmitSurveyQuestionOptionText {
  id: string;
  text: string;
  // 没有勾选的情况，status为1
  status: ESubmitSurveyStatus;
  /**
   * 命中的维度标签
   */
  dim?: string;
  /**
   * 选项后方下划线填写后的值
   */
  other_text?: string;
}

export interface ISubmitSurveyQuestionOption extends ISubmitSuveryQuestionBase {
  options: ISubmitSurveyQuestionOptionText[];
}

export interface ISubmitSurveyQuestionMartix extends ISubmitSuveryQuestionBase {
  groups: IQuestionMatrixResRow[];
}

export interface ISubmitSurveyQuestionTime extends ISubmitSuveryQuestionBase {
  time: string;
  utc_offset: number;
}

export type ISubmitSurveyQuestionResult =
  | ISubmitSuveryQuestionDefault
  | ISubmitSurveyQuestionText
  | ISubmitSurveyQuestionOption
  | ISubmitSurveyQuestionMartix;

export interface IRichTitleModel {
  tag: string;
  style?: Array<{ key: string; value: string }>;
  children?: Array<IRichTitleModel>;
  attr?: Array<{ key: string; value: string }>;
  content?: string;
}

export const enum ERichTitleModelTags {
  P = 'p',
  SPAN = 'span',
  TEXT = 'text',
}
