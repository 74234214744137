/*
 * @Author: wesli
 * @Date: 2021-06-22 15:41:04
 * @Last Modified by: wesli
 * @Last Modified time: 2025-03-31 21:11:46
 */
import { useInViewport } from 'ahooks';
import { useEffect, useRef, HTMLAttributes } from 'react';

interface ICurrentProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  /**
   * 是否曝光
   * @param isExposure 
   * @returns 
   */
  onExposure?: (isExposure: boolean) => void;
  /**
   * 销毁生命周期
   * @returns 
   */
  onDestroy?: () => void;
  children: JSX.Element;
}

/**
 * 曝光容器组件，判断容器是否出现可视范围
 * @param props 
 * @returns 
 */
export function ExposureContainer(props: ICurrentProps) {
  const { children, className, style, onExposure, onDestroy } = props;
  const ref = useRef(null);
  const [inViewport] = useInViewport(ref);
  useEffect(() => {
    if (inViewport !== undefined) {
      onExposure?.(inViewport);
    }
  }, [inViewport, onExposure]);
  useEffect(() => {
    return () => {
      onDestroy?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div ref={ref} className={className} style={style}>
    {children}
  </div>;
}
