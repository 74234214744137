import {
  EQuestionItemSubType,
  EQuestionItemType,
  IQuestionChangeBaseParams,
  IQuestionResText,
} from '@tux-meter-ui/interface/survey';
import { getTitleByQuestionItem } from '@tux-meter-ui/utils/question-render-helper';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { Textarea } from 'tdesign-react';
import { EValidateErrorType, IQuestionItemRef, IQuestionItemValidateResult } from '../interface';
import './index.less';

interface IQuestionTextAreaProps {
  onChange: (value: IQuestionResText, oldValue: IQuestionResText, params: IQuestionChangeBaseParams) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  index?: number;
  value: IQuestionResText;
}

/**
 * 问卷问题-文本域
 * @param {IQuestionTextAreaProps} props
 * @returns
 */
export const QuestionTextArea = forwardRef<IQuestionItemRef, IQuestionTextAreaProps>(
  ({ onChange, onFocus, onBlur, index, value }, ref) => {
    const elementId = useMemo(() => `question-num-${index}`, [index]);
    const ele = useMemo(() => {
      if (value.type !== EQuestionItemType.Text) {
        return null;
      }
      const { sub_type } = value.model;
      // 如果是多行文本则显示4行，反之1行
      const lineItems = sub_type === EQuestionItemSubType.TEXTAREA ? 4 : 1;
      return (
        <div className="task-question-item">
          {value.model.title && (
            <>
              <div>{getTitleByQuestionItem(value.model, index)}</div>
            </>
          )}
          <Textarea
            className="cus-textarea"
            style={{
              // 没有标题的情况，需要加大margin-top，没有标题margin-top为16，默认.textarea的margin-top为8px
              marginTop: value.model.title ? undefined : '16px',
            }}
            placeholder={value.model.description}
            value={value.value}
            autosize={{ minRows: lineItems, maxRows: lineItems }}
            maxlength={1000}
            onChange={(val: string) => {
              const res: IQuestionResText = {
                type: EQuestionItemType.Text,
                model: value.model,
                value: val,
              };
              onChange(res, value, { isDisplayChange: false });
            }}
            onFocus={onFocus ? onFocus : () => {}}
            onBlur={onBlur ? onBlur : () => {}}
          />
        </div>
      );
    }, [value, index, onChange, onFocus, onBlur]);

    function validate(): IQuestionItemValidateResult {
      const { value: resValue, model } = value;
      const res: IQuestionItemValidateResult = {
        result: false,
        errors: [],
      };
      const title = index !== undefined ? `问题${index + 1}` : `问题：${model.title}`;
      if (resValue.length === 0 || resValue === undefined || resValue === '') {
        res.errors.push({
          message: `${title}，尚未填写，请填写后提交`,
          errorType: EValidateErrorType.QUESTION,
          element: document.getElementById(elementId),
        });
        return res;
      }
      res.result = true;
      return res;
    }

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return <div id={elementId}>{ele}</div>;
  },
);
