import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import H5App from './h5/App';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './assets/css/base.less';
import './assets/css/td-ext.less';

// 手动补全 replaceChildren 的 Polyfill
if (!Element.prototype.replaceChildren) {
  Element.prototype.replaceChildren = function (...nodes) {
    while (this.firstChild) this.removeChild(this.firstChild);
    this.append(...nodes);
  };
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <H5App />
  </StrictMode>,
);
