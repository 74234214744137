import { TuxSurveyConfig, ETuxSurveyType, ISubmitSurveyBasePayload } from '@tencent/tux-meter-js-sdk-core';
import { ISubmitSurveyAnswer, ISubmitSurveyQuestionResult } from '@tux-meter-ui/h5/api/survey';
import {
  EMetricType,
  EQuestionItemType,
  IQuestionResItem,
  IQuestionStarChangeParams,
  ISurveyModel,
  ISurveyPage,
} from '@tux-meter-ui/interface/survey';
import { convert2SurveyQuestions } from '@tux-meter-ui/utils/survey-helper';
import { getDisplayTreeNodes, getSubmitQuestions } from '@tux-meter-ui/utils/survey-helper';
import { usePrevious } from 'ahooks';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { TaskErrorIcon } from 'tdesign-icons-react';
import { Empty } from 'tdesign-react';
import { ITuxEventListener } from '../../tux-meter-ui/inteface';
import { QuestionItem } from '../QuestionItem';
import {
  getDisplayTreeNodesByQuestions,
  getFlattenTreeNode,
  getQuestionResItems,
  IDisplayTreeNode,
} from '../QuestionItem/util';
import './index.less';
interface IProps {
  surveyConfig: TuxSurveyConfig;
  tuxEventListener: ITuxEventListener;
}

/**
 * 内嵌量表题视图组件
 * @param {IProps} props
 * @returns
 */
export const TuxNativeStarView = ({ surveyConfig, tuxEventListener }: IProps) => {
  const startedDate = useMemo(() => new Date(), []);
  const legacy = useMemo(() => {
    const survey = surveyConfig.getSurvey();
    const model: ISurveyModel = {
      ...survey,
      last_modified_by: '',
      index_group_id: '',
      metric: EMetricType.CSAT,
      smart_mode: true,
      pages: survey.pages as unknown as ISurveyPage[],
    };
    return model;
  }, [surveyConfig]);
  const [answerMaps, setAnswerMaps] = useState<Record<string, IQuestionResItem>>({});
  const [displayTreeNodes, setDisplayTreeNodes] = useState<IDisplayTreeNode[]>([]);

  /**
   * 接口中获取的所有问题列表
   */
  const metaQuestions = useMemo(() => {
    if (legacy.pages?.[0]?.questions.length === 0) {
      return [];
    }
    // 特殊逻辑，找到所有量表题
    const questions = legacy.pages?.[0]?.questions?.filter((c) => c.type === EQuestionItemType.Star) ?? [];
    return convert2SurveyQuestions(questions);
  }, [legacy]);

  const flatDisplayTreeNodes = useMemo(() => getFlattenTreeNode(displayTreeNodes), [displayTreeNodes]);
  const displays = useMemo(() => {
    const ids = flatDisplayTreeNodes.map((c) => Number(c.id));
    const arr = Array.from(new Set(ids))
      .map((c) => c.toString())
      .filter((c) => c !== '-1');
    // 根据问题顺序处理显示问题顺序
    const result = metaQuestions.reduce((res: string[], question) => {
      const index = arr.findIndex((c) => c === question.id);
      if (index > -1) {
        return [...res, question.id];
      }
      return res;
    }, []);
    return result;
  }, [flatDisplayTreeNodes, metaQuestions]);

  /**
   * 将远程结果全部添加到答题maps中
   */
  useEffect(() => {
    const res: IQuestionResItem[] = getQuestionResItems(metaQuestions);
    const maps = _.keyBy(res, function (item) {
      return item.model.id.toString();
    });
    setAnswerMaps(maps);
  }, [metaQuestions]);
  /**
   * 最终提交的答题结果
   */
  const submitQuestions = useMemo(
    () => getSubmitQuestions(metaQuestions, displays, answerMaps),
    [metaQuestions, displays, answerMaps],
  );

  const submit = useCallback(async () => {
    const started_at = dayjs(startedDate).format('YYYY-MM-DD HH:mm:ss');
    const endedDate = new Date();
    const ended_at = dayjs(endedDate).format('YYYY-MM-DD HH:mm:ss');
    const answer: ISubmitSurveyAnswer[] = surveyConfig.getSurvey().pages.map((page) => {
      const questions = page.questions.reduce((res: ISubmitSurveyQuestionResult[], q) => {
        const submitQuestion = submitQuestions.find((submitQuestion) => submitQuestion.id === q.id);
        if (submitQuestion) {
          return [...res, submitQuestion];
        }
        return res;
      }, []);
      return {
        id: page.id,
        questions,
      };
    });
    const payload: ISubmitSurveyBasePayload = {
      started_at,
      ended_at,
      client_started_at: started_at,
      client_ended_at: ended_at,
      answer,
      start_unix: startedDate.getTime(),
      end_unix: endedDate.getTime(),
    };
    tuxEventListener?.onSurveySubmit?.({
      surveyConfig,
      submitAnswerParams: {
        payload,
        tuxSurveyType: ETuxSurveyType.SELF_RENDING_WITH_TUXH5,
      },
    });
  }, [submitQuestions, surveyConfig, startedDate, tuxEventListener]);

  // 如果路由中存在option_id参数，则获取对应需要默认勾选第一道量表题，并展示量表题相关displays
  const defaultDisplayTreeNodes = useMemo(() => {
    const questions = metaQuestions ?? [];
    const nodes = getDisplayTreeNodesByQuestions(questions);
    return {
      displayNodes: nodes,
      questionRes: undefined,
    };
  }, [metaQuestions]);

  const oldDisplayTreeNodes = usePrevious(defaultDisplayTreeNodes);

  // 如果路由参数option_id匹配了第一道量表题并有默认参数
  useEffect(() => {
    if (defaultDisplayTreeNodes && JSON.stringify(defaultDisplayTreeNodes) !== JSON.stringify(oldDisplayTreeNodes)) {
      const { questionRes, displayNodes } = defaultDisplayTreeNodes;
      setDisplayTreeNodes(displayNodes);
      const displayNode = displayNodes?.[0];
      if (questionRes && displayNode) {
        setAnswerMaps((prevAnswerMaps) => {
          const newMaps = _.cloneDeep(prevAnswerMaps);
          newMaps[displayNode.id] = questionRes;
          return newMaps;
        });
      }
    }
  }, [defaultDisplayTreeNodes, oldDisplayTreeNodes]);

  // 展示的结果
  const displayQuestions = useMemo(() => getDisplayQuestions(displays, answerMaps), [displays, answerMaps]);

  const displayQuestionEles = useMemo(() => {
    const eles = displays.reduce((res: JSX.Element[], display) => {
      const displayNode = flatDisplayTreeNodes.find((c) => c.id === display);
      const question = answerMaps[display];
      if (question && displayNode && legacy) {
        return [
          ...res,
          <QuestionItem
            key={display}
            displayOfQuestionListResults={displayQuestions}
            surveyModel={legacy}
            answerMaps={answerMaps}
            value={question}
            onChange={(val, _x, params) => {
              // 触发问卷问题click事件
              tuxEventListener?.onQuestionClick?.({ surveyConfig });

              const newMaps = _.cloneDeep(answerMaps);
              newMaps[display] = val;
              setAnswerMaps((prevAnswerMaps) => {
                const newMaps = _.cloneDeep(prevAnswerMaps);
                newMaps[display] = val;
                return newMaps;
              });
              if (params.isDisplayChange) {
                const nodes = getDisplayTreeNodes(displayNode, newMaps);
                const node = nodes[0];
                if (node) {
                  setDisplayTreeNodes((displayTreeNodes) => {
                    const displayTreeNodeIndex = displayTreeNodes.findIndex((item) => item.id === node.id);
                    const newArr = _.cloneDeep(displayTreeNodes);
                    newArr[displayTreeNodeIndex] = node;
                    return newArr;
                  });
                }
              }
              const { optionValue } = params as IQuestionStarChangeParams;
              if (question.model.type === EQuestionItemType.Star && optionValue) {
                // 触发量表题回调
                tuxEventListener?.onStarQuestionClick?.({
                  surveyConfig,
                  question: question.model,
                  option: optionValue,
                });
                // 执行预提交
                submit();
              }
            }}
          />,
        ];
      }
      return res;
    }, []);
    return eles;
  }, [surveyConfig, displayQuestions, displays, answerMaps, flatDisplayTreeNodes, legacy, tuxEventListener, submit]);

  /**
   * 获取展示的问题集合
   * @param displays
   * @param answerMaps
   * @returns
   */
  function getDisplayQuestions(displays: string[], answerMaps: Record<string, IQuestionResItem>) {
    const res = displays.reduce((res: IQuestionResItem[], display) => {
      const model: IQuestionResItem | undefined = answerMaps[display];
      if (model) {
        return [...res, model];
      }
      return res;
    }, []);
    return res;
  }

  const mainEle = useMemo(() => {
    // 如果没有找到量表题，提示问卷创建人
    if (displayQuestionEles.length === 0) {
      return (
        <Empty
          image={
            <TaskErrorIcon
              size={64}
              style={{
                color: 'var(--td-text-color-placeholder)',
              }}
            />
          }
          title="问卷异常：问卷至少包含1道量表题"
        />
      );
    }
    return displayQuestionEles;
  }, [displayQuestionEles]);

  return <div className="tux-native-star-view-container">{mainEle}</div>;
};
