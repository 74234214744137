import { Col, Row } from '@tux-meter-ui/components/Grid';
import { IInputTooltipRef, InputTooltip } from '@tux-meter-ui/components/InputTooltip';
import {
  IQuestionOptionChangeParams,
  IQuestionOptionItem,
  IQuestionOptionStyle,
  IQuestionResOption,
} from '@tux-meter-ui/interface/survey';
import { isNumber } from '@tux-meter-ui/utils/number-ext';
import _ from 'lodash';
import { useCallback, useMemo, MouseEvent, useRef, forwardRef, useImperativeHandle } from 'react';
import CusCheckbox from '../CusCheckbox';
import CusRadio from '../CusRadio';
import {
  EValidateErrorType,
  IQuestionItemRef,
  IQuestionItemValidateError,
  IQuestionItemValidateResult,
} from '../interface';

interface IOptionPerLineProps {
  onChange: (value: IQuestionResOption, oldValue: IQuestionResOption, params: IQuestionOptionChangeParams) => void;
  value: IQuestionResOption;
  option: IQuestionOptionItem;
  optionStyle: IQuestionOptionStyle;
}

interface IOptionInputProps {
  onChange: (value: IQuestionResOption, oldValue: IQuestionResOption, params: IQuestionOptionChangeParams) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  option: IQuestionOptionItem;
  value: IQuestionResOption;
}

/**
 * 选项填空组件
 * @param param0
 * @returns
 */
export const OptionInput = forwardRef<IQuestionItemRef, IOptionInputProps>(
  ({ onChange, onClick, option, value: propVal }: IOptionInputProps, ref) => {
    const inputTooltipRef = useRef<IInputTooltipRef>();
    function validate(): IQuestionItemValidateResult {
      const res: IQuestionItemValidateResult = {
        result: false,
        errors: [],
      };
      const curOpt = propVal.value.find((c) => c.id === option.id);
      // 选中的结果中和当前option不匹配，不做校验
      if (!curOpt) {
        res.result = true;
        return res;
      }
      const { add_blank, blank_config, other_text } = curOpt;
      const text = other_text ?? '';
      if (add_blank && blank_config) {
        const { is_required, max_len } = blank_config;
        if (is_required && text.length === 0) {
          const errorModel: IQuestionItemValidateError = {
            message: '填空不能为空',
            errorType: EValidateErrorType.TIP,
            element: inputTooltipRef.current?.currentElement,
            placement: 'bottom-left',
          };
          res.errors.push(errorModel);
          return res;
        }
        if (text.length > max_len) {
          const errorModel: IQuestionItemValidateError = {
            message: `最多可输入${max_len}个字`,
            errorType: EValidateErrorType.TIP,
            element: inputTooltipRef.current?.currentElement,
          };
          res.errors.push(errorModel);
          return res;
        }
      }
      res.result = true;
      return res;
    }
    useImperativeHandle(ref, () => ({
      validate,
    }));
    if (option.add_blank && option.blank_config) {
      const { blank_config } = option;
      const { tip, max_len } = blank_config;
      const optionIndex = propVal.value.findIndex((k) => k.id === option.id);
      return (
        <InputTooltip
          ref={inputTooltipRef}
          keepWrapperWidth
          className="question-option-input"
          placeholder={tip ?? '请输入'}
          // 输入框每次点击时都需要选中前面的选项
          onClick={(context) => onClick?.(context.e)}
          value={option.other_text}
          onChange={(val, ctx) => {
            // 对超出进行截断
            const textVal = val.slice(0, max_len);
            // 输入超出最大长度时，进行提示
            if (val.length > max_len) {
              ctx?.triggerTooltip?.({
                content: `最多可输入${max_len}个字`,
                duration: 2000,
                theme: 'danger',
                placement: 'bottom-left',
              });
            }
            const newOptions = _.cloneDeep(propVal.value);
            // 存在选项后填空时，将输入内容填入
            if (newOptions[optionIndex].blank_config) {
              // 更新原始question model的other_text，然后将option赋值到IQuestionResOption[]中
              // 这样做的好处是当切换选项时，依然能保留之前填写的option.other_text
              option.other_text = textVal;
              newOptions[optionIndex] = option;
              const res: IQuestionResOption = {
                ...propVal,
                value: newOptions,
              };
              onChange(res, propVal, { isDisplayChange: false, optionValue: option });
            }
          }}
        />
      );
    }
    return null;
  },
);

interface IHandleOptionChange {
  value: IQuestionResOption;
  option: IQuestionOptionItem;
  optionStyle: IQuestionOptionStyle;
  /**
   * 是否选中
   */
  selected: boolean;
}

/**
 * 传入选中等参数，获取需要变更的选项结果，如果返回undefined，则表示超出验证个数
 * @param props
 * @returns
 */
function getResOption(props: IHandleOptionChange) {
  const { value, option, optionStyle, selected } = props;
  const { max } = optionStyle;
  let newOptions = _.cloneDeep(value.value);
  const optionIndex = value.value.findIndex((k) => k.id === option.id);
  // 取消选中的情况下移除
  if (!selected) {
    newOptions.splice(optionIndex, 1);
    const res: IQuestionResOption = {
      ...value,
      value: newOptions,
    };
    return res;
  }
  if (
    max?.toString() === '1' ||
    option.exclusive ||
    (newOptions.length > 0 && newOptions[0].exclusive && !option.exclusive)
  ) {
    newOptions = [option];
    const res: IQuestionResOption = {
      ...value,
      value: newOptions,
    };
    return res;
  }
  // 如果不符合验证个数时，返回undefined
  if (max !== undefined && isNumber(max) && Number(max) <= newOptions.length) {
    return undefined;
  }
  if (optionIndex === -1) {
    newOptions.push(option);
  }
  const res: IQuestionResOption = {
    ...value,
    value: newOptions,
  };
  return res;
}

/**
 * 选项题 —— 1行1个
 * @param {IOptionPerLineProps} props
 * @returns
 */
export const SingleOptionPerLine = forwardRef<IQuestionItemRef, IOptionPerLineProps>(
  ({ onChange, option, value: propVal, optionStyle }, ref) => {
    const { max } = optionStyle;
    const inputRef = useRef<IQuestionItemRef>(null);
    const optionIndex = useMemo(() => propVal.value.findIndex((k) => k.id === option.id), [propVal, option]);
    const selected = useMemo(() => optionIndex !== -1, [optionIndex]);
    const handleChange = useCallback(
      (selected: boolean) => {
        const res = getResOption({
          value: propVal,
          option,
          optionStyle,
          selected,
        });
        if (!res) {
          // 验证失败，透传失败信息给父组件
          onChange(propVal, propVal, {
            isDisplayChange: true,
            optionValue: option,
            validateResult: {
              result: false,
              errors: [
                {
                  message: `最多选${max}项`,
                  errorType: EValidateErrorType.TITLE,
                },
              ],
            },
          });
          return;
        }
        onChange(res, propVal, { isDisplayChange: true, optionValue: option });
      },
      [propVal, option, optionStyle, max, onChange],
    );
    // 填空题
    const inputEle: JSX.Element | null = useMemo(
      () => (
        <OptionInput
          ref={inputRef}
          option={option}
          value={propVal}
          // 输入框每次点击时都需要选中前面的选项
          onClick={(e) => {
            // 输入框命中时，直接选中
            e.stopPropagation();
            handleChange(true);
          }}
          onChange={onChange}
        />
      ),
      [option, propVal, handleChange, onChange],
    );
    const operationEle = useMemo(() => {
      return max?.toString() === '1' ? <CusRadio checked={selected} /> : <CusCheckbox checked={selected} />;
    }, [max, selected]);

    function validate(val?: IQuestionResOption): IQuestionItemValidateResult {
      const childValidateErrRes = inputRef.current?.validate() ?? {
        result: false,
        errors: [],
      };
      // 子组件验证失败，返回验证失败信息
      if (childValidateErrRes && !childValidateErrRes.result) {
        return childValidateErrRes;
      }
      const resVal = val ?? propVal;
      if (max !== undefined && max !== '' && isNumber(max) && resVal.value.length > Number(max)) {
        childValidateErrRes.result = false;
        childValidateErrRes.errors = [
          {
            message: `最多可选${max}个`,
            errorType: EValidateErrorType.TITLE,
          },
        ];
        return childValidateErrRes;
      }
      childValidateErrRes.result = true;
      return childValidateErrRes;
    }

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <div className="multi-item">
        <div onClick={() => handleChange(!selected)}>
          <Row align="start" gutter={6}>
            <Col>{operationEle}</Col>
            <Col flex={1} className="pt-1px">
              <div className="inline">
                <span className="inline question-option-text">{option.text}</span>
                {
                  inputEle && option.add_blank && <span className="inline">
                    <div className="inline-block" style={{ width: '180px' }}>
                      {inputEle}
                    </div>
                  </span>
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  },
);

/**
 * 选项题 —— 1行多个
 * @param {IOptionPerLineProps} props
 * @returns
 */
export const MultipleOptionPerLine = forwardRef<IQuestionItemRef, IOptionPerLineProps>(
  ({ onChange, option, value: propVal, optionStyle }, ref) => {
    const { line_items, max } = optionStyle;
    const inputRef = useRef<IQuestionItemRef>(null);
    const optionIndex = useMemo(() => propVal.value.findIndex((k) => k.id === option.id), [propVal, option]);
    const selected = useMemo(() => optionIndex !== -1, [optionIndex]);
    const handleChange = useCallback(
      (selected: boolean) => {
        const res = getResOption({
          value: propVal,
          option,
          optionStyle,
          selected,
        });
        if (!res) {
          // 验证失败，透传失败信息给父组件
          onChange(propVal, propVal, {
            isDisplayChange: true,
            optionValue: option,
            validateResult: {
              result: false,
              errors: [
                {
                  message: `最多选${max}项`,
                  errorType: EValidateErrorType.TITLE,
                },
              ],
            },
          });
          return;
        }
        onChange(res, propVal, { isDisplayChange: true, optionValue: option });
      },
      [propVal, option, optionStyle, max, onChange],
    );
    // 填空题
    const inputEle: JSX.Element | null = useMemo(
      () => (
        <OptionInput
          ref={inputRef}
          option={option}
          value={propVal}
          // 输入框每次点击时都需要选中前面的选项
          onClick={(e) => {
            // 输入框命中时，直接选中
            e.stopPropagation();
            handleChange(true);
          }}
          onChange={onChange}
        />
      ),
      [option, propVal, handleChange, onChange],
    );

    function validate(val?: IQuestionResOption): IQuestionItemValidateResult {
      const childValidateErrRes = inputRef.current?.validate() ?? {
        result: false,
        errors: [],
      };
      // 子组件验证失败，返回验证失败信息
      if (childValidateErrRes && !childValidateErrRes.result) {
        return childValidateErrRes;
      }
      const resVal = val ?? propVal;
      if (max !== undefined && max !== '' && isNumber(max) && resVal.value.length > Number(max)) {
        childValidateErrRes.result = false;
        childValidateErrRes.errors = [
          {
            message: `最多可选${max}个`,
            errorType: EValidateErrorType.TITLE,
          },
        ];
        return childValidateErrRes;
      }
      childValidateErrRes.result = true;
      return childValidateErrRes;
    }

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <Col avg={parseInt(line_items)} className="flex flex-stretch">
        <div className={`multi-item w-full ${selected ? 'selected' : ''}`} onClick={() => handleChange(!selected)}>
          <Row justify="center" align="middle" className="h-full w-full">
            <Col>
              <div className="question-option-text">{option.text}</div>
              {inputEle && option.add_blank && <div style={{ padding: '0 12px' }}>{inputEle}</div>}
            </Col>
          </Row>
        </div>
      </Col>
    );
  },
);
