import imageProtol from '@tux-meter-ui/assets/protol.png';
import { Col, Row } from '@tux-meter-ui/components/Grid';
import { TuxUIConstants } from '@tux-meter-ui/constant';
import {
  EQuestionItemType,
  IQuestionChangeBaseParams,
  IQuestionResSensitiveInput,
} from '@tux-meter-ui/interface/survey';
import { getTitleByQuestionItem } from '@tux-meter-ui/utils/question-render-helper';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Dialog, Input, InputRef } from 'tdesign-react';
import CusCheckbox from '../CusCheckbox';
import { EValidateErrorType, IQuestionItemRef, IQuestionItemValidateResult } from '../interface';
import './index.less';

interface IQuestionSensitiveProps {
  index?: number;
  onChange: (
    value: IQuestionResSensitiveInput,
    oldValue: IQuestionResSensitiveInput,
    params: IQuestionChangeBaseParams,
  ) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: IQuestionResSensitiveInput;
}

export const QuestionSensitive = forwardRef<IQuestionItemRef, IQuestionSensitiveProps>(
  ({ index, onChange, onFocus, onBlur, value }, ref) => {
    const elementId = useMemo(() => `question-num-${index}`, [index]);
    const [protolVisible, setProtolVisible] = useState(false);
    const confirmRef = useRef<HTMLLabelElement>(null);
    const inputRef = useRef<InputRef>(null);

    const ele = useMemo(() => {
      const item = value;
      if (item.type !== EQuestionItemType.SensitiveInput) {
        return null;
      }
      let showProtol = { license: 1 };
      try {
        showProtol = JSON.parse(item.model.style);
      } catch (error) {
        console.log(error);
      }

      return (
        <div className="task-question-item task-question-sensitive">
          <div>{getTitleByQuestionItem(item.model, index)}</div>
          <div className="input-cotainer">
            <Input
              ref={inputRef}
              className="t-input-text"
              placeholder={item.model.description}
              value={item.value}
              maxlength={100}
              onChange={(val) => {
                const res: IQuestionResSensitiveInput = {
                  type: EQuestionItemType.SensitiveInput,
                  model: item.model,
                  value: val,
                  confirm: item.confirm,
                };
                onChange(res, item, { isDisplayChange: false });
              }}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
          {showProtol.license === 1 && (
            <Row className="mt-8px" align="middle">
              <Col className="line-height">
                <CusCheckbox
                  ref={confirmRef}
                  checked={item.confirm}
                  onChange={(checked: boolean) => {
                    const res: IQuestionResSensitiveInput = {
                      type: EQuestionItemType.SensitiveInput,
                      model: item.model,
                      value: item.value,
                      confirm: checked,
                    };
                    onChange(res, item, { isDisplayChange: false });
                  }}
                />
              </Col>
              <Col>我已阅读并同意</Col>
              <Col>
                <a className="cus-link" href="javascript:void(0);" onClick={() => setProtolVisible(true)}>
                  《填答者协议》
                </a>
              </Col>
              <Col>和</Col>
              <Col>
                <a
                  className="cus-link"
                  href="javascript:void(0);"
                  onClick={() => window.open(TuxUIConstants.SENSITIVE_INFO_URL)}
                >
                  《腾讯问卷隐私政策》
                </a>
              </Col>
            </Row>
          )}
        </div>
      );
    }, [index, value, onChange, onFocus, onBlur]);

    function validate(): IQuestionItemValidateResult {
      const { value: resValue, confirm } = value;
      const res: IQuestionItemValidateResult = {
        result: false,
        errors: [],
      };
      if (resValue.length === 0 || !resValue) {
        res.errors.push({
          message: '手机号不能为空',
          errorType: EValidateErrorType.TIP,
          element: inputRef.current?.currentElement,
        });
        return res;
      }
      if (resValue.length > 0 && !/^\d{11}$/.test(resValue)) {
        res.errors.push({
          message: '请输入有效的手机号码',
          errorType: EValidateErrorType.TIP,
          element: inputRef.current?.currentElement,
        });
        return res;
      }
      if (!confirm) {
        res.errors.push({
          message: `尚未勾选协议`,
          errorType: EValidateErrorType.TIP,
          element: confirmRef.current,
        });
        return res;
      }
      res.result = true;
      return res;
    }

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <div className="sensitive-item-wrap" id={elementId}>
        {ele}
        <Dialog visible={protolVisible} header={false} footer={false} onClose={() => setProtolVisible(false)}>
          <img className="inline-block" src={imageProtol} width="100%" alt="协议" />
        </Dialog>
      </div>
    );
  },
);
