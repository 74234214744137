import {
  TuxSurveyConfig,
  ETuxSurveyType,
  ISubmitSurveyBasePayload,
  ISubmitSurveyAnswer,
} from '@tencent/tux-meter-js-sdk-core';
import {
  getDisplayTreeNodesByQuestions,
  getFlattenTreeNode,
  getQuestionResItems,
  IDisplayTreeNode,
  scrollIntoView,
} from '@tux-meter-ui/components/QuestionItem/util';
import {
  EMetricType,
  EQuestionItemType,
  IQuestionItem,
  IQuestionResItem,
  IQuestionResStar,
  IQuestionStarChangeParams,
  ISubmitSurveyQuestionResult,
  ISurveyModel,
  ISurveyPage,
} from '@tux-meter-ui/interface/survey';
import { ISubmitValidateResult } from '@tux-meter-ui/interface/tux-meter-ui-interface';
import { ITuxEventListener, ITuxNativeSurveySetting } from '@tux-meter-ui/tux-meter-ui/inteface';
import { isNumber } from '@tux-meter-ui/utils/number-ext';
import {
  convert2SurveyQuestions,
  getDescendantDisplaysByNode,
  isRadioQuestion,
} from '@tux-meter-ui/utils/survey-helper';
import { getDisplayTreeNodes, getSubmitQuestions } from '@tux-meter-ui/utils/survey-helper';
import TooltipPlugin from '@tux-meter-ui/utils/tooltip-plugin';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useState, useEffect, useCallback, useMemo, useImperativeHandle, forwardRef, useRef } from 'react';
import { MessagePlugin } from 'tdesign-react';
import { ExposureContainer } from '../ExposureContainer';
import { QuestionItem } from '../QuestionItem';
// import { usePrevious } from 'ahooks';
import {
  EValidateErrorType,
  IQuestionItemRef,
  IQuestionItemValidateError,
  IQuestionItemValidateResult,
} from '../QuestionItem/interface';
import './index.less';

interface IProps {
  surveyConfig: TuxSurveyConfig;
  setting: ITuxNativeSurveySetting;
  tuxEventListener?: ITuxEventListener;
  /**
   * 问卷页码索引
   */
  surveyPageIndex?: number;
  /**
   * 默认量表题选中的选项ID
   */
  starOptionId?: string;
  /**
   * 展示的问题列表发生变化
   * @param displayQuestions
   * @returns
   */
  onDisplayQuestionsChange?: (displayQuestions: IQuestionResItem[]) => void;
  /**
   * 问卷页码值变更
   * @param surveyPageIndex
   * @returns
   */
  onSurveyPageIndexChange?: (surveyPageIndex: number) => void;
}

export interface ITuxNativeBaseSurveyViewRef {
  /**
   * 切换上一页
   * @returns
   */
  prevPageIndex: () => Promise<number>;
  /**
   * 切换下一页
   * @returns
   */
  nextPageIndex: () => Promise<number>;
  /**
   * 提交问卷
   * @returns
   */
  submit: () => Promise<ISubmitValidateResult>;
}

/**
 * Tux原生问卷基础视图
 * @param {IProps} props
 * @returns
 */
export const TuxNativeBaseSurveyView = forwardRef<ITuxNativeBaseSurveyViewRef, IProps>(
  (
    { surveyConfig, setting, tuxEventListener, starOptionId, onDisplayQuestionsChange, onSurveyPageIndexChange },
    ref,
  ) => {
    const questionItemRefs = useRef<(IQuestionItemRef | null)[]>([]);
    const startedDate = useMemo(() => new Date(), []);
    const legacy = useMemo(() => {
      const survey = surveyConfig.getSurvey();
      const model: ISurveyModel = {
        ...survey,
        last_modified_by: '',
        index_group_id: '',
        metric: EMetricType.CSAT,
        smart_mode: true,
        pages: survey.pages as unknown as ISurveyPage[],
      };
      return model;
    }, [surveyConfig]);

    const metaSurveyPages = useMemo(() => legacy?.pages.filter((c) => !c.is_deleted) ?? [], [legacy.pages]);
    const surveyPages = useMemo(() => metaSurveyPages.filter((c) => c.questions.length > 0), [metaSurveyPages]);
    const [answerMaps, setAnswerMaps] = useState<Record<string, IQuestionResItem>>({});
    const [displayTreeNodes, setDisplayTreeNodes] = useState<IDisplayTreeNode[]>([]);
    const [surveyPageIndex, setSurveyPageIndex] = useState<number>(0);
    const timeoutRef = useRef<number | null>(null);

    // 接口中获取的所有问题列表
    const metaQuestions = useMemo(() => {
      if (legacy.pages) {
        const questions = legacy.pages.reduce((res: IQuestionItem[], page) => {
          return [...res, ...page.questions];
        }, []);
        return convert2SurveyQuestions(questions);
      }
      return [];
    }, [legacy]);

    const currSurveyPage = useMemo(() => {
      const pages = legacy.pages?.filter((c) => c.questions.length > 0);
      return pages?.[surveyPageIndex];
    }, [legacy.pages, surveyPageIndex]);

    const flatDisplayTreeNodes = useMemo(() => getFlattenTreeNode(displayTreeNodes), [displayTreeNodes]);
    // 全部分页需要展示的题型列表
    const displays = useMemo(() => {
      const ids = flatDisplayTreeNodes.map((c) => Number(c.id));
      const arr = Array.from(new Set(ids))
        .map((c) => c.toString())
        .filter((c) => c !== '-1');
      // 根据问题顺序处理显示问题顺序
      const displays = metaQuestions.reduce((res: string[], question) => {
        const index = arr.findIndex((c) => c === question.id);
        if (index > -1) {
          return [...res, question.id];
        }
        return res;
      }, []);
      return displays;
    }, [flatDisplayTreeNodes, metaQuestions]);
    // 当前页码下需要展示的题型列表
    const currSurveyPageDisplays = useMemo(() => {
      const result = displays.filter((display) => {
        const index = currSurveyPage.questions?.findIndex((c) => c.id === display);
        return index > -1;
      });
      return result;
    }, [displays, currSurveyPage]);

    const submit = useCallback(
      async (displayQuestions: IQuestionResItem[], answerMaps: Record<string, IQuestionResItem>) => {
        // 展示的问题id
        const displays = displayQuestions.map((c) => c.model.id);
        // 最终提交的答题结果
        const submitQuestions = getSubmitQuestions(metaQuestions, displays, answerMaps);
        const errorValidateResults = questionItemRefs.current.reduce((res: IQuestionItemValidateResult[], item) => {
          if (item?.validate) {
            const validateRes = item?.validate();
            if (!validateRes.result) {
              return [...res, item.validate()];
            }
          }
          return res;
        }, []);
        // 打平后的所有错误信息
        const errors = errorValidateResults.reduce((res: IQuestionItemValidateError[], validateRes) => {
          if (!validateRes.result) {
            return [...res, ...validateRes.errors];
          }
          return res;
        }, []);
        if (errors.length > 0) {
          // 具体的问题实现中如果validate验证失败时，并且返回了element，这时把滚动条定位到该元素
          const res: ISubmitValidateResult = {
            validate: false,
            errors,
          };
          const err = errors[0];
          if (err.errorType === EValidateErrorType.TIP && err.element !== null && err.element !== undefined) {
            scrollIntoView(err?.element);
            // 为题目元素添加tooltip
            TooltipPlugin.error({
              ele: err.element,
              visible: true,
              duration: 2000,
              content: err.message ?? '校验题型发生未知错误',
              placement: err?.placement ?? 'bottom-left',
            });
            return res;
          }
          scrollIntoView(err.element);
          if (err.errorType === EValidateErrorType.QUESTION) {
            // 展示问题维度报错的弹窗
            MessagePlugin.warning(err?.message ?? '验证问题必填失败，错误信息丢失');
          }
          return res;
        }
        const started_at = dayjs(startedDate).format('YYYY-MM-DD HH:mm:ss');
        const endedDate = new Date();
        const ended_at = dayjs(endedDate).format('YYYY-MM-DD HH:mm:ss');
        const answer: ISubmitSurveyAnswer[] = surveyConfig.getSurvey().pages.map((page) => {
          const questions = page.questions.reduce((res: ISubmitSurveyQuestionResult[], q) => {
            const submitQuestion = submitQuestions.find((submitQuestion) => submitQuestion.id === q.id);
            if (submitQuestion) {
              return [...res, submitQuestion];
            }
            return res;
          }, []);
          return {
            id: page.id,
            questions,
          };
        });
        const payload: ISubmitSurveyBasePayload = {
          started_at,
          ended_at,
          client_started_at: started_at,
          client_ended_at: ended_at,
          answer,
          start_unix: startedDate.getTime(),
          end_unix: endedDate.getTime(),
        };
        tuxEventListener?.onSurveySubmit?.({
          surveyConfig,
          submitAnswerParams: {
            payload,
            tuxSurveyType: ETuxSurveyType.ALL_SELF_RENDERING,
          },
        });
        const res: ISubmitValidateResult = {
          validate: true,
          errors: [],
          payload,
        };
        return res;
      },
      [surveyConfig, metaQuestions, startedDate, tuxEventListener],
    );

    // 只有一道题的时候（单选题类型交互题目， 单道题的情况下直接点击后提交，不需要按钮），问卷文本居中显示
    const isOnlyOne = useMemo(() => {
      const questions = metaQuestions.filter((c) => c.is_deleted !== true);
      return questions.length === 1 && isRadioQuestion(questions?.[0]) && !!setting.isAlone;
    }, [metaQuestions, setting.isAlone]);

    // 展示的结果
    const displayQuestions = useMemo(() => getDisplayQuestions(displays, answerMaps), [displays, answerMaps]);

    // 上一页
    const prevPageIndex = useCallback(async () => {
      const pageIndex = surveyPageIndex - 1 < 0 ? 0 : surveyPageIndex - 1;
      setSurveyPageIndex(pageIndex);
      tuxEventListener?.onSurveyPageIndexChange?.(pageIndex);
      return pageIndex;
    }, [surveyPageIndex, tuxEventListener]);

    // 下一页
    const nextPageIndex = useCallback(async () => {
      const res = await submit(displayQuestions, answerMaps);
      if (res.validate) {
        const pageIndex = surveyPageIndex + 1 > surveyPages.length - 1 ? surveyPageIndex : surveyPageIndex + 1;
        setSurveyPageIndex(pageIndex);
        tuxEventListener?.onSurveyPageIndexChange?.(pageIndex);
        return pageIndex;
      }
      return surveyPageIndex;
    }, [submit, displayQuestions, answerMaps, surveyPageIndex, surveyPages, tuxEventListener]);

    const displayQuestionEles = useMemo(() => {
      const eles = displays.reduce((res: JSX.Element[], display, index) => {
        const displayNode = flatDisplayTreeNodes.find((c) => c.id === display);
        const question = answerMaps[display];
        // 如果是当前页码时，显示问题
        const isCurrPageQuestion = currSurveyPageDisplays.findIndex((d) => d === display) > -1;
        if (question && displayNode && legacy && isCurrPageQuestion) {
          return [
            ...res,
            <ExposureContainer
              key={index}
              onExposure={isExposure => {
                // 曝光时添加曝光停留时间
                if (isExposure) {
                  const time = dayjs().format('YYYY-MM-DD HH:mm:ss.SSS');
                  surveyConfig.getAnswerDuration()
                    .updateAnswerTime(time)
                    .updateQuestionExposureTime(question.model.id, time);
                }
              }}
              onDestroy={() => {
                // 销毁时，更新最后曝光时间
                const time = dayjs().format('YYYY-MM-DD HH:mm:ss.SSS');
                surveyConfig.getAnswerDuration()
                  .updateAnswerTime(time)
                  .updateQuestionExposureTime(question.model.id, time);
              }}
            >
              <QuestionItem
                ref={(el) => (questionItemRefs.current[index] = el)}
                key={display}
                index={index}
                displayOfQuestionListResults={displayQuestions}
                surveyModel={legacy}
                answerMaps={answerMaps}
                value={question}
                onChange={(val, _x, params) => {
                  const clickTime = dayjs().format('YYYY-MM-DD HH:mm:ss.SSS');
                  // 上报题目点击时间
                  surveyConfig.getAnswerDuration().updateQuestionClickTime(val.model.id, clickTime);
                  const optionChangeParams = (params as IQuestionStarChangeParams);
                  // 如果是选择题
                  if (optionChangeParams?.optionValue) {
                    // 上报选项点击时间
                    surveyConfig.getAnswerDuration().updateOptionClickTime(val.model.id, optionChangeParams.optionValue.id, clickTime);
                  }
                  // 触发问卷问题click事件
                  tuxEventListener?.onQuestionClick?.({ surveyConfig });
                  const newMaps = _.cloneDeep(answerMaps);
                  newMaps[display] = val;
                  setAnswerMaps((prevAnswerMaps) => {
                    const newMaps = _.cloneDeep(prevAnswerMaps);
                    newMaps[display] = val;
                    // 只有一道题的情况下需要触发自动提交
                    if (isOnlyOne) {
                      // 清除之前的定时器
                      if (timeoutRef.current !== null) {
                        clearTimeout(timeoutRef.current);
                      }
                      // x秒后再触发问卷提交，防止用户看不到单选选中样式
                      timeoutRef.current = window.setTimeout(() => {
                        const displayQuestions = getDisplayQuestions(displays, newMaps);
                        submit(displayQuestions, newMaps);
                      }, 200);
                      return newMaps;
                    }
                    return newMaps;
                  });
                  if (params.isDisplayChange) {
                    const nodes = getDisplayTreeNodes(displayNode, newMaps);
                    const node = nodes[0];
                    if (node) {
                      // 后代问题displays（包含当前问题id）
                      const descendantDisplays = getDescendantDisplaysByNode(node);
                      setDisplayTreeNodes((displayTreeNodes) => {
                        const displayTreeNodeIndex = displayTreeNodes.findIndex((item) => item.id === node.id);
                        const newArr = _.cloneDeep(displayTreeNodes);
                        if (displayTreeNodeIndex > -1) {
                          newArr[displayTreeNodeIndex] = node;
                        }
                        // 当前问题不存在后代问题 & 当前页码下展示的问题默认只有1题 & 当前问题是单选操作 & 开启自动提交功能
                        const canAutoNextPage =
                          descendantDisplays.length === 1 &&
                          currSurveyPageDisplays.length === 1 &&
                          isRadioQuestion(question.model) &&
                          !!setting.isAutoNextPage;
                        if (canAutoNextPage) {
                          // x秒后再触发问卷下一页，防止用户看不到单选选中样式
                          setTimeout(() => {
                            nextPageIndex();
                          }, 200);
                        }
                        return newArr;
                      });
                    }
                  }
                }}
              />
            </ExposureContainer>,
          ];
        }
        return res;
      }, []);
      return eles.reduce((res: JSX.Element[], ele, index) => {
        if (index < eles.length - 1) {
          return res.concat(ele, <div key={`divider-${index}`} className="task-question-item divider"></div>);
        } else {
          return res.concat(ele);
        }
      }, []);
    }, [
      displays,
      currSurveyPageDisplays,
      answerMaps,
      flatDisplayTreeNodes,
      legacy,
      displayQuestions,
      surveyConfig,
      tuxEventListener,
      isOnlyOne,
      submit,
      setting,
      nextPageIndex,
    ]);

    // 将远程结果全部添加到答题maps中
    useEffect(() => {
      const res: IQuestionResItem[] = getQuestionResItems(metaQuestions);
      const maps = _.keyBy(res, function (item) {
        return item.model.id.toString();
      });
      setAnswerMaps(maps);
    }, [metaQuestions]);

    /**
     * 获取展示的问题集合
     * @param displays
     * @param answerMaps
     * @returns
     */
    function getDisplayQuestions(displays: string[], answerMaps: Record<string, IQuestionResItem>) {
      const res = displays.reduce((res: IQuestionResItem[], display) => {
        const model: IQuestionResItem | undefined = answerMaps[display];
        if (model) {
          return [...res, model];
        }
        return res;
      }, []);
      return res;
    }

    // 如果路由中存在option_id参数，则获取对应需要默认勾选第一道量表题，并展示量表题相关displays
    const defaultDisplayTreeNodes = useMemo(() => {
      const questions = metaQuestions ?? [];
      const firstStarQuestion = questions.find((c) => c.type === EQuestionItemType.Star);
      const nodes = getDisplayTreeNodesByQuestions(questions);
      const nodeIndex = nodes.findIndex((c) => c.id === firstStarQuestion?.id && c.children.length === 0);
      const displayNode = nodes[nodeIndex];
      // 如果第一道题是量表题，进行匹配url参数option_id
      if (firstStarQuestion && isNumber(starOptionId) && displayNode) {
        const option = firstStarQuestion.options.find((c) => c.id === starOptionId);
        const res: IQuestionResStar = {
          type: EQuestionItemType.Star,
          model: _.cloneDeep(firstStarQuestion),
          value: option ? [_.cloneDeep(option)] : [],
        };
        nodes[nodeIndex].children =
          option?.displays.map((c, index) => {
            const model: IDisplayTreeNode = {
              id: c,
              indexPath: [...nodes[nodeIndex].indexPath, index],
              children: [],
            };
            return model;
          }) ?? [];
        return {
          displayNodes: nodes,
          questionRes: res,
        };
      }
      return {
        displayNodes: nodes,
        questionRes: undefined,
      };
    }, [metaQuestions, starOptionId]);

    // const oldDisplayTreeNodes = usePrevious(defaultDisplayTreeNodes);

    // 如果路由参数option_id匹配了第一道量表题并有默认参数
    useEffect(() => {
      if (defaultDisplayTreeNodes) {
        const { questionRes, displayNodes } = defaultDisplayTreeNodes;
        setDisplayTreeNodes(displayNodes);
        const displayNode = displayNodes?.[0];
        if (questionRes && displayNode) {
          setAnswerMaps((prevAnswerMaps) => {
            const newMaps = _.cloneDeep(prevAnswerMaps);
            newMaps[displayNode.id] = questionRes;
            return newMaps;
          });
        }
      }
    }, [defaultDisplayTreeNodes]);

    useEffect(() => {
      onDisplayQuestionsChange?.(displayQuestions);
    }, [displayQuestions, onDisplayQuestionsChange]);

    useEffect(() => {
      onSurveyPageIndexChange?.(surveyPageIndex);
    }, [surveyPageIndex, onSurveyPageIndexChange]);

    useImperativeHandle(ref, () => ({
      /**
       * 上一页
       */
      prevPageIndex,
      /**
       * 下一页
       */
      nextPageIndex,
      /**
       * 提交问卷内容
       */
      submit: () => submit(displayQuestions, answerMaps),
    }));

    return <div className="tux-native-base-survey-view">{displayQuestionEles}</div>;
  },
);
