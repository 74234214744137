import { Col, Row } from '@tux-meter-ui/components/Grid';
import { ImageViewer } from '@tux-meter-ui/components/ImageViewer';
import { TitleTag } from '@tux-meter-ui/components/TitleTag';
import {
  EAspectRatio,
  EQuestionItemSubType,
  EQuestionItemType,
  IQuestionOptionImageChangeParams,
  IQuestionOptionItem,
  IQuestionOptionStyle,
  IQuestionResOption,
} from '@tux-meter-ui/interface/survey';
import { isNumber } from '@tux-meter-ui/utils/number-ext';
import { getMaxTipsEle, getOptionTypeEle, getTitleByQuestionItem } from '@tux-meter-ui/utils/question-render-helper';
import _ from 'lodash';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import CusCheckbox from '../CusCheckbox';
import CusRadio from '../CusRadio';
import { EValidateErrorType, IQuestionItemRef, IQuestionItemValidateResult } from '../interface';
import './index.less';

interface IQuestionOptionImageProps {
  onChange: (value: IQuestionResOption, oldValue: IQuestionResOption, params: IQuestionOptionImageChangeParams) => void;
  index?: number;
  value: IQuestionResOption;
}

/**
 * 问卷问题-选项题（图片类型）
 * @param {IQuestionOptionImageProps} props
 * @returns
 */
export const QuestionOptionImage = forwardRef<IQuestionItemRef, IQuestionOptionImageProps>(
  ({ onChange, index, value }, ref) => {
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const elementId = useMemo(() => `option-image-doc-id-${index}`, [index]);

    const styleObj = useMemo(() => {
      const { style } = value.model;
      const styleObj: IQuestionOptionStyle = style
        ? JSON.parse(style.replace(/: "/g, ':"'))
        : {
            image_aspect_ratio: EAspectRatio.ONE_TO_ONE,
            line_items: '3',
          };
      return styleObj;
    }, [value.model]);

    const ele = useMemo(() => {
      const item = value;
      if (item.type !== EQuestionItemType.Option) {
        return null;
      }
      const { options, sub_type } = item.model;
      const displayOptions = options.filter((c) => c.is_deleted !== true);
      const { line_items, max, image_aspect_ratio } = styleObj;
      const aspectRatioArr = image_aspect_ratio?.split(':') ?? [];
      const aspect_ratio_style = {
        aspectRatio: `${aspectRatioArr[0]} / ${aspectRatioArr[1]}`,
      };

      const renderOptions = (options: IQuestionOptionItem[]) => {
        return options.map((option) => {
          const optionIndex = item.value.findIndex((k) => k.id === option.id);
          const selected = optionIndex !== -1;
          const handleChange = () => {
            let newOptions = _.cloneDeep(item.value);
            if (selected) {
              newOptions.splice(optionIndex, 1);
            } else {
              if (
                max?.toString() === '1' ||
                option.exclusive ||
                (newOptions.length > 0 && newOptions[0].exclusive && !option.exclusive)
              ) {
                newOptions = [option];
                const res: IQuestionResOption = {
                  ...item,
                  value: newOptions,
                };
                onChange(res, item, { isDisplayChange: true, optionValue: option });
                return;
              }
              if (max !== undefined && isNumber(max) && Number(max) <= newOptions.length) {
                setErrorMsg(`最多选可选${max}项`);
                return;
              }
              newOptions.push(option);
            }
            const res: IQuestionResOption = {
              ...item,
              value: newOptions,
            };
            onChange(res, item, { isDisplayChange: true, optionValue: option });
          };

          return (
            <Col span={parseInt(line_items)} className="flex flex-stretch" key={option.id}>
              <div className="option-image-item">
                <div className="image-container-aspect-ratio" style={aspect_ratio_style}>
                  <div className="w-full h-full uploaded-panel">
                    <ImageViewer
                      imageUrl={option.image}
                      renderContent={() => (
                        <div
                          style={{ backgroundImage: `url(${option.image})` }}
                          className="responsive-image-aspect-ratio"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="w-full h-full relative">
                  <Row justify="center" align="middle" className="w-full pt-10px pb-10px pl-12px pr-12px">
                    <Col>
                      {sub_type === EQuestionItemSubType.IMAGE_CHECKBOX ? (
                        <CusCheckbox checked={selected} onChange={handleChange}>
                          <span className="c-666 font-weight-normal">{option.text}</span>
                        </CusCheckbox>
                      ) : (
                        <CusRadio checked={selected} onChange={handleChange}>
                          <span className="c-666 font-weight-normal">{option.text}</span>
                        </CusRadio>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          );
        });
      };

      return (
        <div>
          <div className="title">
            <Row gutter={8}>
              <Col>{getTitleByQuestionItem(item.model, index)}</Col>
              <Col>{getOptionTypeEle(item.model)}</Col>
              {errorMsg && (
                <Col>
                  <TitleTag
                    duration={2000}
                    onClose={() => {
                      setErrorMsg(undefined);
                    }}
                  >
                    {errorMsg}
                  </TitleTag>
                </Col>
              )}
            </Row>
          </div>
          {sub_type === EQuestionItemSubType.IMAGE_RADIO && !item.model.required
            ? null
            : getMaxTipsEle(max, item.model.required)}
          <div className="multi-container-pop">
            <Row gutter={[24, 24]} align="stretch">
              {renderOptions(displayOptions)}
            </Row>
          </div>
        </div>
      );
    }, [value, index, styleObj, errorMsg, onChange]);

    function validate(): IQuestionItemValidateResult {
      const { value: resValue, model } = value;
      const res: IQuestionItemValidateResult = {
        result: false,
        errors: [],
      };
      const title = index !== undefined ? `问题${index + 1}` : `问题：${model.title}`;
      if (resValue.length === 0) {
        res.errors.push({
          message: `${title}，尚未选择，请勾选后提交`,
          errorType: EValidateErrorType.QUESTION,
          element: document.getElementById(elementId),
        });
        return res;
      }
      res.result = true;
      return res;
    }

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return (
      <div className="survey-question-option task-question-item-image" id={elementId}>
        {ele}
      </div>
    );
  },
);
