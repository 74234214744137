import { TuxSurveyConfig } from '@tencent/tux-meter-js-sdk-core';
import {
  EMetricType,
  EQuestionItemType,
  IQuestionResItem,
  ISurveyModel,
  ISurveyPage,
} from '@tux-meter-ui/interface/survey';
import { convert2SurveyQuestions, isRadioQuestion } from '@tux-meter-ui/utils/survey-helper';
import { useMemo, useRef, useState } from 'react';
import { ITuxEventListener, ITuxNativeSurveySetting } from '../../tux-meter-ui/inteface';
import { SubmitButton } from '../SubmitButton';
import { ITuxNativeBaseSurveyViewRef, TuxNativeBaseSurveyView } from '../TuxNativeBaseSurveyView';
import './index.less';

interface IProps {
  surveyConfig: TuxSurveyConfig;
  setting: ITuxNativeSurveySetting;
  tuxEventListener?: ITuxEventListener;
  /**
   * 默认量表题选中的选项ID
   */
  starOptionId?: string;
}

/**
 * Tux嵌入式问卷视图(component_type:6)
 * @param {IProps} props
 * @returns
 */
export const TuxNativeEmbeddedView = ({ surveyConfig, setting, tuxEventListener, starOptionId }: IProps) => {
  const tuxNativeBaseSurveyViewRef = useRef<ITuxNativeBaseSurveyViewRef>(null);
  const [displayQuestions, setDisplayQuestions] = useState<IQuestionResItem[]>([]);
  const needSubmitBtn = useMemo(() => {
    // 必填的总数
    const total = displayQuestions.filter((c) => c.model.required).length;
    const count = displayQuestions.reduce((res: number, item) => {
      if (item.model.required) {
        const { type, value } = item;
        if (type === EQuestionItemType.Star || type === EQuestionItemType.Option) {
          if (value.length > 0) {
            return res + 1;
          }
        }
        if (type === EQuestionItemType.Text) {
          if (value.length !== 0 && value !== undefined) {
            return res + 1;
          }
        }
      }
      return res;
    }, 0);
    return total > 0 && total === count;
  }, [displayQuestions]);

  const legacy = useMemo(() => {
    const survey = surveyConfig.getSurvey();
    const model: ISurveyModel = {
      ...survey,
      last_modified_by: '',
      index_group_id: '',
      metric: EMetricType.CSAT,
      smart_mode: true,
      pages: survey.pages as unknown as ISurveyPage[],
    };
    return model;
  }, [surveyConfig]);

  // 接口中获取的所有问题列表
  const metaQuestions = useMemo(() => {
    if (legacy.pages?.[0]?.questions.length === 0) {
      return [];
    }
    const questions = legacy.pages?.[0]?.questions ?? [];
    return convert2SurveyQuestions(questions);
  }, [legacy]);

  // 只有一道题的时候（单选题类型交互题目， 单道题的情况下直接点击后提交，不需要按钮），问卷文本居中显示
  const isOnlyOne = useMemo(() => {
    const questions = metaQuestions.filter((c) => c.is_deleted !== true);
    return questions.length === 1 && isRadioQuestion(questions?.[0]) && setting.isAlone;
  }, [metaQuestions, setting.isAlone]);

  return (
    <div className="tux-native-embedded-view" style={{ height: '100%' }}>
      <TuxNativeBaseSurveyView
        ref={tuxNativeBaseSurveyViewRef}
        surveyConfig={surveyConfig}
        setting={setting}
        tuxEventListener={tuxEventListener}
        starOptionId={starOptionId}
        onDisplayQuestionsChange={(displayQuestions) => {
          setDisplayQuestions(displayQuestions);
        }}
      />
      {needSubmitBtn && !isOnlyOne ? (
        <div className="pb-48px">
          <SubmitButton
            onSubmit={async () => {
              await tuxNativeBaseSurveyViewRef?.current?.submit();
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
