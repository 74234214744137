import { beacon } from '@tux-meter-ui/h5/utils/beacon';
import { useRoute } from '@tux-meter-ui/h5/utils/router-helper';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * 获取问卷query参数
 * @returns 
 */
export function useSurveyQueryArgs() {
  const route = useRoute();
  const [q36, setQ36] = useState<string | undefined>();
  const [q36Init, setQ36Init] = useState<boolean>(false);
  const appId = useMemo(() => (route.query.app_id as string) ?? '', [route.query.app_id]);
  const surveyId = useMemo(() => (route.query.survey_id as string) ?? '', [route.query.survey_id]);
  const surveyType = useMemo(() => {
    // CSAT或NPS 转换为小写
    return route.params.survey_type?.toLocaleLowerCase();
  }, [route.params.survey_type]);
  const zoneId = useMemo(() => {
    // zone_id作为区服id，后续存在改名的情况，它作为子id。
    // 用于and条件触发问卷，比如uid&sub_uid匹配的情况下弹出问卷
    const sub_uid = route.query.sub_uid as string | undefined;
    return sub_uid ?? (route.query.zone_id as string) ?? '';
  }, [route.query.sub_uid, route.query.zone_id]);
  const cbFns = useMemo(() => {
    const fns = (route.query.cb_fns as string) ?? '';
    return fns.split(',');
  }, [route.query.cb_fns]);
  const uuid = useMemo(() => {
    const task_id = route.query.task_id as string | undefined;
    const uid = route.query.uid as string | undefined;
    // 如果有task_id和uid的情况，则使用这两个参数拼接为uuid
    // 单一uuid的queryArg用户难以理解为什么要拼接成这样，例如: 001330-2f64f5f5256149b66221f29910001a215328
    // 所以兼容url参数可以为: survey_id=111&task_id=1330&uid=2f64f5f5256149b66221f29910001a215328
    if (task_id && uid) {
      // 补充id为6位
      const left = _.padStart(task_id, 6, '0');
      // 拼接uuid
      // 文档参考https://iwiki.woa.com/pages/viewpage.action?pageId=1689393596
      return `${left}-${uid}`;
    }
    const taskinfo_id = route.query.taskinfo_id as string | undefined;
    // 如果有传递task信息的id过来，则使用uuid拼接
    if (taskinfo_id && q36) {
      // 补充id为6位
      const left = _.padStart(taskinfo_id, 6, '0');
      // 拼接uuid
      // 文档参考https://iwiki.woa.com/pages/viewpage.action?pageId=1689393596
      return `${left}-${q36}`;
    }
    return (route.query.uuid as string) ?? '';
  }, [route.query, q36]);
  // userId取之于uuid的第一个 - 后的所有值
  // uuid可能为 001234-abccxaxa 或者 01234-1j414-2121n
  // 上述实例就需要userId为 abccxaxa 或 1j414-2121n
  const userId = useMemo(() => {
    const [taskIdStr] = uuid.split('-');
    return uuid.replace(`${taskIdStr}-`, '');
  }, [uuid]);
  useEffect(() => {
    if (window?.browser?.app?.getBrowserParam) {
      window?.browser?.app?.getBrowserParam?.(
        (pararms) => {
          const { qimei36 } = pararms;
          setQ36(qimei36);
          setQ36Init(true);
        },
        (error) => {
          setQ36Init(true);
          beacon.onDirectUserAction('tux_h5_error_log', {
            tux_zone_id: zoneId,
            env: window.ENV_CONSTANT.CLINT_ENV,
            iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
            tux_uid: userId,
            tux_uuid: uuid || 'test',
            survey_id: surveyId,
            survey_type: surveyType,
            server_type: 'H5',
            msg: `从 getBrowserParam 获取q36失败：${error}`,
            app_id: route.query.app_id ?? 'qb',
          });
        },
      );
      return;
    }
    setQ36(route.query.q36 as string);
    setQ36Init(true);
  }, [route.query.app_id, route.query.q36, surveyId, surveyType, userId, uuid, zoneId]);
  return {
    q36,
    q36Init,
    appId,
    surveyId,
    zoneId,
    surveyType,
    userId,
    uuid,
    cbFns,
  };
}