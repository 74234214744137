import { useDebounceFn } from 'ahooks';
import axios from 'axios';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { JsApiList, WxConfigOptions } from 'types/jwexin';
import { useRoute } from '../utils/router-helper';

interface IWechatConfig {
  baseUrl: string;
  appId: string;
  timestamp: number;
  nonceStr: string;
  signature: string;
}

interface IWechatConfigRes {
  code: number;
  msg: string;
  wxConfig?: IWechatConfig;
}

interface IUseWechatProps {
  jsApiList: JsApiList[]
  wechatDebug?: boolean;
}

export function useWechat(props: IUseWechatProps) {
  const { jsApiList, wechatDebug } = props;
  const route = useRoute();
  const [wechatConfig, setWechatConfig] = useState<Omit<WxConfigOptions, 'jsApiList'> | undefined>();
  const [wxConfigOptions, setWxConfigOptions] = useState<WxConfigOptions | undefined>();
  const [wechatIsReady, setWechatIsReady] = useState(false);
  const { run: getWechatConfig } = useDebounceFn(async (url: string) => {
    const res = await axios.post<IWechatConfigRes>('/wechat-config', { url });
    const wechatRes = res.data;
    if (wechatRes && wechatRes.code === 0 && wechatRes.wxConfig) {
      setWechatConfig(_.omit(wechatRes.wxConfig, ['baseUrl']));
    }
  }, { wait: 50 });
  const fullPath = useMemo(() => {
    if (window.location.href.indexOf(route.fullPath) > -1) {
      return window.location.href.split('#')[0];
    }
    return undefined;
  }, [route.fullPath]);
  useEffect(() => {
    if (fullPath) {
      getWechatConfig(fullPath);
    }
  }, [fullPath, getWechatConfig]);
  useEffect(() => {
    if (wechatConfig) {
      const options: WxConfigOptions = {
        appId: wechatConfig.appId ?? '',
        timestamp: wechatConfig.timestamp?.toString() ?? '',
        nonceStr: wechatConfig.nonceStr ?? '',
        signature: wechatConfig.signature ?? '',
        jsApiList,
        debug: wechatDebug,
      };
      setWxConfigOptions(preVal => {
        if (JSON.stringify(options) !== JSON.stringify(preVal)) {
          return options;
        }
        return preVal;
      });
    }
  }, [wechatConfig, jsApiList, wechatDebug]);
  useEffect(() => {
    if (wxConfigOptions) {
      console.log('wxConfigOptions:', wxConfigOptions);
      wx.config(wxConfigOptions);
      wx.ready(() => {
        setWechatIsReady(true);
        console.log('wx ready');
      });
      wx.error((res) => {
        console.log('wx error:', res);
      });
    }
  }, [wxConfigOptions]);
  return {
    wechatConfig,
    wechatIsReady,
  };
}
