import {
  EQuestionItemType,
  ESubmitSurveyStatus,
  IQuestionItem,
  IQuestionOptionStyle,
  IQuestionResItem,
  ISubmitSurveyQuestionMartix,
  ISubmitSurveyQuestionOption,
  ISubmitSurveyQuestionOptionText,
  ISubmitSurveyQuestionResult,
  ISubmitSurveyQuestionText,
  IQuestionMatrix,
  IQuestionOption,
  IQuestionOptionItem,
  IQuestionMatrixResRow,
  IQuestionSubTitle,
  EQuestionItemSubType,
  ISubmitSurveyQuestionTime,
} from '@tux-meter-ui/interface/survey';
import _ from 'lodash';
import { SimpleDOMPurify } from './simple-dom-purify';

/**
 * 随机打乱数据元素
 * @param array 
 * @example const arr: { id: string; text: string; is_deleted: boolean }[] = [
  { "id": "1", "text": "行标题1", "is_deleted": false },
  { "id": "2", "text": "行标题2", "is_deleted": true },
  { "id": "3", "text": "行标题3", "is_deleted": false },
  { "id": "4", "text": "行标题4", "is_deleted": false }
];
const shuffledArr = shuffleArray(arr);
 * @returns 
 */
function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

/**
 * 处理转换option类型的问题（比如处理option的随机，固定选项）
 * @param question
 */
export function convert2SurveyOption(question: IQuestionOption): IQuestionOption {
  // 如果是多选题，并且已勾选随机选项，将多选题的选项打乱顺序（is_random为空的也打乱）
  if (question.is_random || question.is_random === undefined) {
    const currOptions = _.cloneDeep(question.options).filter((c) => c.is_deleted !== true);
    // 获取固定的选项索引数组
    const fixedIndexArr = currOptions.reduce((res: number[], option, index) => {
      if (option.is_fixed) {
        return [...res, index];
      }
      return res;
    }, []);
    const randomIndexArr = currOptions.reduce((res: number[], option, index) => {
      if (!option.is_fixed) {
        return [...res, index];
      }
      return res;
    }, []);
    const newOptions: IQuestionOptionItem[] = [];
    // 现将固定的选项，按照旧有的位置填充至newOptions
    fixedIndexArr.forEach((index) => {
      newOptions[index] = currOptions[index];
    });
    // 打乱需要随机的索引
    const newRandomIndexArr = _.sortBy(randomIndexArr, () => _.random(0, randomIndexArr.length));
    randomIndexArr.forEach((oldIndex, index) => {
      const newIndex = newRandomIndexArr[index];
      // 将旧的model(currOptions[oldIndex])赋值到新的options中
      newOptions[newIndex] = currOptions[oldIndex];
    });
    const res: IQuestionOption = {
      ...question,
      options: newOptions,
    };
    return res;
  }
  return question;
}

/**
 * 处理转换matrix类型的问题（比如处理matrix的随机，固定选项）
 * @param question
 */
export function convert2SurveyMatrix(question: IQuestionMatrix): IQuestionMatrix {
  // 如果是多选题，并且已勾选随机选项，将多选题的选项打乱顺序（is_random为空的也打乱）
  if (question.is_random || question.is_random === undefined) {
    const subTitles = _.cloneDeep(question.sub_titles ?? []).filter((c) => c.is_deleted !== true);
    const sub_titles = shuffleArray(subTitles);
    const res: IQuestionMatrix = {
      ...question,
      sub_titles: sub_titles as IQuestionSubTitle[],
    };
    return res;
  }
  return question;
}

/**
 * 处理默认数据返回的questions（比如处理option的随机，固定选项）
 * @param questions
 * @returns
 */
export function convert2SurveyQuestions(questions: IQuestionItem[]): IQuestionItem[] {
  const newQuestions: IQuestionItem[] = questions.map((c) => {
    if (c.type === EQuestionItemType.Option) {
      return convert2SurveyOption(c);
    }
    if (c.type === EQuestionItemType.Matrix) {
      return convert2SurveyMatrix(c);
    }
    return c;
  });
  return newQuestions;
}

export interface IDisplayTreeNode {
  id: string;
  indexPath: number[];
  children: IDisplayTreeNode[];
}

/**
 * 递归获取树形结构列表
 * @param displayNode
 * @param answerMaps
 * @returns
 */
export function getDisplayTreeNodes(
  displayNode: IDisplayTreeNode,
  answerMaps: Record<string, IQuestionResItem>,
): IDisplayTreeNode[] {
  const { id, indexPath } = displayNode;
  const info = answerMaps[id];
  if (info && info.type !== EQuestionItemType.Text) {
    const { type, value, model } = info;
    // 永远只显示结果的第一个匹配的displays，旧逻辑
    let children =
      type === EQuestionItemType.Option || type === EQuestionItemType.Star
        ? (value?.[0] as IQuestionOptionItem)?.displays?.map((k, index) => {
          const model: IDisplayTreeNode = {
            id: k,
            indexPath: [...indexPath, index],
            children: [],
          };
          return model;
        })
        : [];
    if (type === EQuestionItemType.Option) {
      const { style } = model;

      const styleObj: IQuestionOptionStyle = style
        ? JSON.parse(style.replace(/: "/g, ':"'))
        : {
          line_items: '3',
        };
      const { multiple } = styleObj;
      // 如果有multiple属性，则表示不互斥（第二道题假设为多选，有选项2A, 2B）
      // 当勾选2A展示3A和3B
      // 当勾选2B展示3C和3D
      // 勾选2A和2B的同时展示[2A, 2B, 3A, 3B, 3C, 3D]
      if (multiple) {
        children = (value as IQuestionOptionItem[]).reduce((res: IDisplayTreeNode[], item) => {
          const arr =
            item?.displays?.map((k, index) => {
              const currIndex = res.length + index;
              const model: IDisplayTreeNode = {
                id: k,
                indexPath: [...indexPath, currIndex],
                children: [],
              };
              return model;
            }) ?? [];
          return [...res, ...arr];
        }, []);
      }
    }
    displayNode.children =
      children?.reduce((res: IDisplayTreeNode[], child) => {
        return [...res, ...getDisplayTreeNodes(child, answerMaps)];
      }, []) ?? [];
    return [displayNode];
  }
  return [displayNode];
}

/**
 * 获取提交需要的问题结构
 * @param question
 * @param displays
 * @param answerMaps
 * @returns
 */
export function getSubmitQuestions(
  question: IQuestionItem[],
  displays: string[],
  answerMaps: Record<string, IQuestionResItem>,
) {
  const res: ISubmitSurveyQuestionResult[] = question.map((item) => {
    const index = displays.findIndex((display) => display === item.id);
    if (index > -1) {
      const display = displays[index];
      const answerMap = answerMaps[display];
      const { type, value, model: metaModel } = answerMap;
      const id = display.toString();
      switch (type) {
        case EQuestionItemType.Star: {
          const model: ISubmitSurveyQuestionText = {
            id,
            type: item.type,
            sub_type: item.sub_type,
            // 量表题提交选中的索引0的text即可
            text: (value as IQuestionOptionItem[])?.[0]?.text ?? '',
            dim: metaModel.dim,
          };
          return model;
        }
        case EQuestionItemType.Option: {
          const options =
            item.type === EQuestionItemType.Option
              ? item.options.map((c) => {
                const index = (value as IQuestionOptionItem[]).findIndex((k) => c.id === k.id);
                const status = index > -1 ? ESubmitSurveyStatus.Selected : ESubmitSurveyStatus.Unselected;
                const model: ISubmitSurveyQuestionOptionText = {
                  id: c.id,
                  text: c.text,
                  status,
                  dim: c.dim,
                  other_text: value[index]?.other_text,
                };
                return model;
              })
              : [];
          const model: ISubmitSurveyQuestionOption = {
            id,
            type: item.type,
            sub_type: item.sub_type,
            options,
            dim: metaModel.dim,
          };
          return model;
        }
        case EQuestionItemType.Matrix: {
          const model: ISubmitSurveyQuestionMartix = {
            id,
            type: item.type,
            sub_type: item.sub_type,
            groups: value as IQuestionMatrixResRow[],
            dim: metaModel.dim,
          };
          return model;
        }
        case EQuestionItemType.SensitiveInput:
        case EQuestionItemType.Text: {
          // 对文本进行xss攻击处理
          const purifier = new SimpleDOMPurify();
          const text = purifier.sanitize((value as string) ?? '');
          const textAreaModel: ISubmitSurveyQuestionText = {
            id,
            type: item.type,
            sub_type: item.sub_type,
            text,
            dim: metaModel.dim,
          };
          return textAreaModel;
        }
        case EQuestionItemType.Time: {
          const result: ISubmitSurveyQuestionTime = {
            id,
            type: item.type,
            sub_type: item.sub_type,
            time: value.time,
            utc_offset: value.utc_offset,
          };
          return result;
        }
        default:
          console.error(`getSubmitQuestions 函数没有对 ${item.type} 类型进行处理，请解决`);
          break;
      }
    }
    // 不存在已选答案的情况，只返回id
    return {
      id: item.id,
      dim: item.dim,
    };
  });
  return res;
}

/**
 * 传入DisplayTree节点，获取这个节点下所有后代的displays字段（包含传入的节点id）
 * @param {IDisplayTreeNode} node
 */
export function getDescendantDisplaysByNode(node: IDisplayTreeNode): string[] {
  const result = node.children.reduce((res: string[], child) => {
    return [...res, child.id, ...getDescendantDisplaysByNode(child)];
  }, []);
  return Array.from(new Set([node.id, ...result]));
}

/**
 * 是否单选类型操作
 * @param question
 * @returns
 */
export function isRadioQuestion(question?: IQuestionItem) {
  switch (question?.sub_type) {
    case EQuestionItemSubType.STAR_STAR:
    case EQuestionItemSubType.STAR_TEXT:
    case EQuestionItemSubType.RADIO:
    case EQuestionItemSubType.IMAGE_RADIO:
    case EQuestionItemSubType.MATRIX_RADIO:
      return true;
    default:
      return false;
  }
}

/**
 * 是否选择题（单选多选都算选择题）
 * @param question 
 * @returns 
 */
export function isChoiceQuestion(question?: IQuestionItem) {
  switch (question?.type) {
    case EQuestionItemType.Star:
    case EQuestionItemType.Option:
    case EQuestionItemType.Matrix:
      return true;
    default:
      return false;
  }
}

/**
 * 是否只有一道题
 * @param metaQuestions
 * @returns
 */
export function isOnlyOneQuestion(metaQuestions: IQuestionItem[]) {
  const questions = metaQuestions.filter((c) => c.is_deleted !== true);
  return questions.length === 1;
}
