import { IRouterComponent } from '@tux-meter-ui/h5/utils/router-helper';
import { Csat } from '@tux-meter-ui/h5/view/Csat';
import CsatOverflow from '@tux-meter-ui/h5/view/CsatOverflow';
import SurveyPreview from '@tux-meter-ui/h5/view/SurveyPreview';
import SurveySuccessView from '@tux-meter-ui/h5/view/SurveySuccessView';
import { Route } from 'react-router-dom';
import { PreFetchView } from '../view/PreFetchView';

// 懒加载 SurveySuccessView 组件
export const routes: IRouterComponent[] = [
  {
    name: 'Csat',
    path: '/tux/overflow/:survey_type',
    component: <CsatOverflow />,
  },
  {
    name: 'Csat',
    path: '/tux/:survey_type',
    component: <PreFetchView renderSurveyView={(props) => <Csat {...props} />} />,
  },
  {
    name: 'SurveyPreview',
    path: '/survey-preview',
    component: <SurveyPreview />,
  },
  {
    name: 'SurveySuccessView',
    path: '/survey-success-view',
    component: <SurveySuccessView />,
  },
  {
    name: 'Home',
    path: '/',
    component: <PreFetchView renderSurveyView={(props) => <Csat {...props} />} />,
  },
];

/**
 * 根据 routes 获取所有路由组件
 * @param routes
 * @returns
 */
export function getRouterComponents(routes: IRouterComponent[]) {
  return routes.map((route) => {
    const { path, component, children } = route;
    if (children && children.length > 0) {
      return (
        <Route key={path} path={path}>
          {getRouterComponents(children)}
        </Route>
      );
    }
    return <Route key={path} path={path} element={component} />;
  });
}
