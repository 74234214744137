// 导入普通态和选择态的图片
import img1 from '@tux-meter-ui/assets/CSAT/1.png';
import img1Selected from '@tux-meter-ui/assets/CSAT/1_selected.png';
import img2 from '@tux-meter-ui/assets/CSAT/2.png';
import img2Selected from '@tux-meter-ui/assets/CSAT/2_selected.png';
import img3 from '@tux-meter-ui/assets/CSAT/3.png';
import img3Selected from '@tux-meter-ui/assets/CSAT/3_selected.png';
import img4 from '@tux-meter-ui/assets/CSAT/4.png';
import img4Selected from '@tux-meter-ui/assets/CSAT/4_selected.png';
import img5 from '@tux-meter-ui/assets/CSAT/5.png';
import img5Selected from '@tux-meter-ui/assets/CSAT/5_selected.png';
import imageStarFill from '@tux-meter-ui/assets/svgs/star-fill.svg';
import imageStar from '@tux-meter-ui/assets/svgs/star.svg';
import { Col, Row } from '@tux-meter-ui/components/Grid';
import {
  IQuestionResStar,
  IQuestionStarChangeParams,
  IQuestionStarStyle,
} from '@tux-meter-ui/interface/survey';
import { getTitleByQuestionItem } from '@tux-meter-ui/utils/question-render-helper';
import { useSize } from 'ahooks';
import classNames from 'classnames';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { EValidateErrorType, IQuestionItemRef, IQuestionItemValidateResult } from '../interface';
import './index.less';

interface ICsatImageInfo {
  normal: string;
  selected: string;
}

// 创建一个映射对象
const csatImages: Record<string, ICsatImageInfo> = {
  '1': { normal: img1, selected: img1Selected },
  '2': { normal: img2, selected: img2Selected },
  '3': { normal: img3, selected: img3Selected },
  '4': { normal: img4, selected: img4Selected },
  '5': { normal: img5, selected: img5Selected },
};

interface IQuestionStarProps {
  onChange: (value: IQuestionResStar, oldValue: IQuestionResStar, params: IQuestionStarChangeParams) => void;
  index?: number;
  value: IQuestionResStar;
}

/**
 * 问卷问题-量表题
 * @param {IQuestionStarProps} props
 * @returns
 */
export const QuestionStar = forwardRef<IQuestionItemRef, IQuestionStarProps>(({ onChange, index, value }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const size = useSize(containerRef);
  const containerWidth = useMemo(() => size?.width ?? 0, [size?.width]);
  const elementId = useMemo(() => `question-num-${index}`, [index]);
  const styleObj = useMemo(() => {
    const styleObj: IQuestionStarStyle = value.model.style
      ? JSON.parse(value.model.style.replace(/: "/g, ':"'))
      : { star_question_render_style: 'emoji' };
    return styleObj;
  }, [value.model]);

  const starType = useMemo(() => styleObj.star_question_render_style, [styleObj.star_question_render_style]);

  const ele = useMemo(() => {
    const options = value.model.options ?? [];
    if (starType === 'text') {
      const optionLength = options.length;
      const textEles = options.map((option, optionIndex) => {
        const selected = value.value?.[0]?.id === option.id;
        return <Col avg={optionLength} key={`${option.id}_${optionIndex}`}>
          <div
            className={classNames('text-center score-item', { selected })}
            onClick={() => {
              const res: IQuestionResStar = {
                ...value,
                value: [option],
              };
              onChange(res, value, { isDisplayChange: true, optionValue: option });
            }}
          >{option.id}</div>
        </Col>;
      });
      const optionTextPlacement = styleObj.option_text_placement ?? 'top';
      const length = options.length;
      const optionDescriptionEles = options.reduce((res: JSX.Element[], option, index) => {
        if (index === 0 && option.id !== option.text) {
          return [
            ...res,
            <div
              style={{
                position: 'absolute',
                left: 0,
              }}
            >
              {option.text}
            </div>
          ];
        }
        if (index === length - 1 && option.id !== option.text) {
          return [
            ...res,
            <div
              style={{
                position: 'absolute',
                right: 0,
              }}
            >
              {option.text}
            </div>
          ];
        }
        const computeMidStyle = (index: number) => {
          // 每个分数的平均宽度
          const avgWidth = containerWidth / length;
          const centerPointer = (index * avgWidth) + avgWidth / 2;
          const left = centerPointer;
          return left;
        };
        if (option.id !== option.text) {
          return [
            ...res,
            <div
              style={{
                position: 'absolute',
                left: (computeMidStyle(index)),
                transform: 'translateX(-50%)',
              }}
            >
              {option.text}
            </div>
          ];
        }
        return res;
      }, []);
      return (
        <>
          <div className='description-panel'>
            {value.model.description && <span className="description">{value.model.description}</span>}
          </div>
          <div className="score-items-panel">
            {
              optionTextPlacement !== 'bottom' && <div className="score-description top">
                {optionDescriptionEles}
              </div>
            }
            <Row justify='space-between' gutter={8}>{textEles}</Row>
            {
              optionTextPlacement === 'bottom' && <div className="score-description bottom">
                {optionDescriptionEles}
              </div>
            }
          </div>
        </>
      );
    }

    const emojiOptionEles = options.map((option, optionIndex) => {
      const selected = value.value?.[0]?.id === option.id;
      const csatImage = csatImages[option.id];
      const imgPath = selected ? csatImage.selected : csatImage.normal;
      return (
        <Col flex={1} key={`${option.id}_${optionIndex}`} >
          <div
            onClick={() => {
              const res: IQuestionResStar = {
                ...value,
                value: [option],
              };
              onChange(res, value, { isDisplayChange: true, optionValue: option });
            }}
          >
            <Row justify="center">
              <Col>
                <img src={imgPath} width="30" height="30" alt="" />
              </Col>
            </Row>
            <Row justify="center" className="mt-4px">
              <Col>
                <span className="fs-12px c-assist-8">{option.text}</span>
              </Col>
            </Row>
          </div>
        </Col>
      );
    });

    const starOptionEles = options.map((option, optionIndex) => {
      const selected = value.value?.[0]?.id >= option.id;
      return (
        <Col flex={1} key={`${option.id}_${optionIndex}`}>
          <div className="star-item">
            <Row justify="center" className="w-100-p">
              <Col>
                <img
                  width="30"
                  height="30"
                  src={selected ? imageStarFill : imageStar}
                  onClick={() => {
                    const res: IQuestionResStar = {
                      ...value,
                      value: [option],
                    };
                    onChange(res, value, { isDisplayChange: true, optionValue: option });
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      );
    });

    return (
      <div className="img-div mt-18px">
        <Row>{starType === 'star' ? starOptionEles : emojiOptionEles}</Row>
      </div>
    );
  }, [containerWidth, value, starType, styleObj, onChange]);

  function validate(): IQuestionItemValidateResult {
    const { value: resValue, model } = value;
    const res: IQuestionItemValidateResult = {
      result: false,
      errors: [],
    };
    const title = index !== undefined ? `问题${index + 1}` : `问题：${model.title}`;
    if (resValue.length === 0) {
      res.errors.push({
        message: `${title}，尚未选择，请勾选后提交`,
        errorType: EValidateErrorType.QUESTION,
        element: document.getElementById(elementId),
      });
      return res;
    }
    res.result = true;
    return res;
  }

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <div className="task-question-item client-question-star" id={elementId} ref={containerRef}>
      <div>{getTitleByQuestionItem(value.model, index)}</div>
      {ele}
    </div>
  );
});
