import ImageSurveySuccess from '@tux-meter-ui/h5/assets/survey-success.png';
import { Col, Row } from '@tux-meter-ui/h5/components/Grid';
import styles from './index.module.less';

/**
 * 问卷提交成功页
 */
function SurveySuccessView() {
  return (
    <div className={styles['survey-success-view']}>
      <Row className="w-100-p h-100-p" justify="center" align="middle">
        <Col>
          <Row justify="center">
            <Col>
              <img src={ImageSurveySuccess} style={{ width: 132 }} alt="" />
            </Col>
          </Row>
          <Row justify="center" className={styles['text-panel']}>
            <Col>
              <span className={styles.text}>感谢您参与问卷调研！</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default SurveySuccessView;
