import { beacon } from '@tux-meter-ui/h5/utils/beacon';
import { useSurveyQueryArgs } from '@tux-meter-ui/utils/surveyQueryArgs';
import { useCallback, useEffect } from 'react';

/**
 * 问卷错误捕捉
 * @returns 
 */
export function SurveyErrorBoundary() {
  const {
    appId,
    surveyId,
    surveyType,
    userId,
    uuid,
  } = useSurveyQueryArgs();
  const reportLog = useCallback((event: ErrorEvent | PromiseRejectionEvent) => {
    let result = '';
    if (event instanceof PromiseRejectionEvent) {
      result = `【PromiseRejectionEvent】:${event.reason?.stack || event.reason?.toString()}`;
    } else {
      const { message, filename, lineno, colno, error } = event;
      result = `【GlobalError】:${JSON.stringify({
        type: 'GLOBAL_ERROR',
        message,
        filename,
        line: lineno,
        column: colno,
        stack: error?.stack
      })}`;
    }
    beacon.onDirectUserAction('tux_h5_error_log', {
      env: window.ENV_CONSTANT.CLINT_ENV,
      iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
      tux_uid: userId,
      tux_uuid: uuid || 'test',
      survey_id: surveyId,
      survey_type: surveyType,
      server_type: 'H5',
      msg: result,
      app_id: appId,
    });
  }, [userId, uuid, surveyId, surveyType, appId]);
  useEffect(() => {
    function errorFn(event: ErrorEvent) {
      reportLog(event);
      event.preventDefault(); // 阻止默认控制台输出
    }
    function unhandledrejectionFn(event: PromiseRejectionEvent) {
      reportLog(event);
      event.preventDefault();
    }
    // 全局错误捕获
    window.addEventListener('error', errorFn);
    // 未处理的Promise拒绝
    window.addEventListener('unhandledrejection', unhandledrejectionFn);

    return () => {
      window.removeEventListener('error', errorFn);
      window.removeEventListener('unhandledrejection', unhandledrejectionFn);
    };
  }, [reportLog]);
  return null;
}
