import { HtmlRender } from '@tux-meter-ui/components/HtmlRender';
import { EQuestionItemSubType, IQuestionItem, IRichNode } from '@tux-meter-ui/interface/survey';
import classnames from 'classnames';
import _ from 'lodash';
import { CSSProperties, Fragment } from 'react';

/**
 * 根据富文本json字符串获取IRichNode数组
 * @param rich_text_title
 * @returns
 */
export function getRichTextTitleNodes(rich_text_title?: string): IRichNode[] {
  if (rich_text_title === undefined || rich_text_title === '') {
    return [];
  }
  try {
    const nodes: IRichNode[] = JSON.parse(rich_text_title);
    if (_.isArray(nodes)) {
      return nodes.map(node => {
        // 如果这个单行p标签里面只有一个br子的情况，我们给这个p添加个className
        if (node.tag === 'p' && node.children?.length === 1 && node.children[0].tag === 'br') {
          if (!node.attr) {
            node.attr = [];
          }
          const classNameIndex = node.attr.findIndex(c => c.key === 'class');
          const className = node.attr[classNameIndex] ?? '';
          const newClassName = classnames(className, 'p-single-br');
          if (classNameIndex === -1) {
            node.attr.push({
              key: 'class',
              value: newClassName,
            });
            return node;
          }
          node.attr[classNameIndex].value = newClassName;
          return node;
        }
        return node;
      });
    }
  } catch (err) {
    // 检查错误对象是否是 Error 类型
    if (err instanceof Error) {
      console.log('转换 rich_text_title  捕获到错误:', err.message);
      console.log('转换 rich_text_title  错误堆栈:', err.stack);
    } else {
      // 如果错误对象不是 Error 类型，直接打印
      console.log('转换 rich_text_title 捕获到未知类型的错误:', err);
    }
  }
  return [];
}

/**
 * 根据传入富文本节点构建html
 * @param {IRichNode[]} nodes 
 * @returns {JSX.Element}
 */
export function getHtmlFragments(nodes: IRichNode[]) {
  return <Fragment>
    {nodes.map((node, index) => (
      <HtmlRender node={node} key={index} />
    ))}
  </Fragment>;
}

/**
 * 传入IRichNode[]类型的JSON String
 * @param {JSON.stringify(IRichNode[])} str 
 * @returns {JSX.Element}
 */
export function getHtmlFragmentsByString(str: string) {
  const nodes = getRichTextTitleNodes(str);
  return getHtmlFragments(nodes);
}

/**
 * 获取题目标题
 * @param question 问题对象
 * @param index 问题索引
 * @returns 题目标题的 JSX 元素
 */
export function getTitleByQuestionItem(question: IQuestionItem, index?: number) {
  const { required, title, rich_text_title } = question;
  const richNodes = getRichTextTitleNodes(rich_text_title);
  const titleEle =
    richNodes.length > 0 ? (
      <Fragment>
        {richNodes.map((node, index) => (
          <HtmlRender node={node} key={index} />
        ))}
      </Fragment>
    ) : (
      <Fragment>{title}</Fragment>
    );
  const style: CSSProperties = {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.86)',
  };
  // 如果不需要索引，则不显示题目序号
  if (index === undefined) {
    return <span style={style}>{titleEle}</span>;
  }
  const paddedIndex = _.padStart((index + 1).toString(), 2, '0');
  if (required) {
    return (
      <span style={style}>
        <span style={{ ...style, color: '#ea4352' }}>*</span>
        <span className="mr-4px" style={style}>{paddedIndex}</span>
        {titleEle}
      </span>
    );
  }
  return (
    <span style={style}>
      <span className="mr-4px" style={style}>{paddedIndex}</span>
      {titleEle}
    </span>
  );
}

/**
 * 获取最大提示元素
 * @param max 最大可选数
 * @param required 是否必填
 * @param prefix 前缀
 * @returns 最大提示的 JSX 元素
 */
export function getMaxTipsEle(max?: string, required?: boolean, prefix?: string) {
  const prefixTitle = prefix ?? '';
  // 多选情况，但没有最大可选数时，并且必填
  if (max === undefined && required) {
    return <div className="mt-12px fs-14px c-666">{prefixTitle}请至少选择1项</div>;
  }
  // 最大可选为1时，并且必填
  if (max?.toString() === '1' && required) {
    return <div className="mt-12px fs-14px c-666">{prefixTitle}请选择1项</div>;
  }
  // 存在最大可选数
  if (max !== undefined) {
    return (
      <div className="mt-12px fs-14px c-666">
        {required ? `${prefixTitle}请选1-${max}项` : `${prefixTitle}最多可选${max}项`}
      </div>
    );
  }
  return null;
}

/**
 * 根据问题子类型返回标题
 * @param subType
 * @returns
 */
export function getSubTypeTitle(question: IQuestionItem) {
  const { sub_type } = question;
  switch (sub_type) {
    case EQuestionItemSubType.CHECKBOX:
    case EQuestionItemSubType.IMAGE_CHECKBOX:
    case EQuestionItemSubType.MATRIX_CHECKBOX:
      return '多选';
    case EQuestionItemSubType.RADIO:
    case EQuestionItemSubType.IMAGE_RADIO:
    case EQuestionItemSubType.MATRIX_RADIO:
      return '单选';
    default:
      return '';
  }
}

/**
 * 获取当前题目是单选还是多选
 * @param max
 * @returns
 */
export function getOptionTypeEle(question: IQuestionItem) {
  const title = getSubTypeTitle(question);
  return <span className="question-title-tag fs-14px">{title}</span>;
}
