import { ITuxNativeBaseSurveyViewRef } from '@tux-meter-ui/components/TuxNativeBaseSurveyView';
import { Col, Row } from '@tux-meter-ui/h5/components/Grid';
import { useWechat } from '@tux-meter-ui/h5/hooks/wechat-helper';
import { EQuestionItemType, IQuestionResItem, ISurveyModel } from '@tux-meter-ui/h5/interface/survey';
import { HtmlHelper } from '@tux-meter-ui/h5/utils/html-helper';
import { useRoute, useRouter } from '@tux-meter-ui/h5/utils/router-helper';
import { getTuxSurveyConfigByLegacy } from '@tux-meter-ui/h5/utils/survey-helper';
import { TuxMeterUI } from '@tux-meter-ui/index';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MessagePlugin, Progress } from 'tdesign-react';
import './index.less';
import { SurveyGuideContent } from '../SurveyGuideContent';

interface QuestionPreviewerProps {
  legacy: ISurveyModel;
}

/**
 * 问卷预览器
 */
const QuestionPreviewer: React.FC<QuestionPreviewerProps> = ({ legacy }) => {
  const route = useRoute();
  const router = useRouter();
  const {
    wechatIsReady,
  } = useWechat({
    jsApiList: ['updateAppMessageShareData'],
    wechatDebug: window.ENV_CONSTANT.WECHAT_DEBUG === '1',
  });
  const [surveyPageIndex, setSurveyPageIndex] = useState<number>(0);
  const [displayQuestions, setDisplayQuestions] = useState<IQuestionResItem[]>([]);
  const [surveyEle, setSurveyEle] = useState<HTMLElement | undefined>();
  const surveyPanelRef = useRef<HTMLDivElement | null>(null); // 修改为 HTMLDivElement
  const getSurveyPanelRef = useRef<() => ITuxNativeBaseSurveyViewRef | null>();

  const title = useMemo(() => {
    const title = route.query.title ?? '';
    return _.isString(title) && title.length > 0 ? title : '问卷预览';
  }, [route.query.title]);

  useEffect(() => {
    HtmlHelper.setTitle(`${title} - TUX`);
  }, [title]);

  const surveyPages = useMemo(() => legacy.pages ?? [], [legacy.pages]);

  // 构建 TuxMeterUI.getTuxNativeBaseSurveyView 函数需要的参数
  const tuxSurveyConfig = useMemo(() => {
    if (legacy) {
      return getTuxSurveyConfigByLegacy(legacy);
    }
    return undefined;
  }, [legacy]);

  /**
   * 下一页需要展示的问题（用于控制是否展示下一页或提交按钮）
   */
  const nextPageDisplayQuestions = useMemo(() => {
    const pages = surveyPages.filter(c => !c.is_deleted && c.questions.length > 0);
    const nextPage = pages[surveyPageIndex + 1];
    if (nextPage) {
      return displayQuestions.filter(displayQuestion => {
        const index = nextPage.questions.findIndex(question => question.id === displayQuestion.model.id);
        return index > -1;
      });
    }
    return [];
  }, [surveyPageIndex, surveyPages, displayQuestions]);

  useEffect(() => {
    if (tuxSurveyConfig) {
      const { surveyEle, getRef } = TuxMeterUI.getTuxNativeBaseSurveyView({
        surveyConfig: tuxSurveyConfig,
        setting: {},
        starOptionId: '',
        tuxEventListener: {
          onDisplayQuestionsChange: (displayQuestions) => {
            setDisplayQuestions(displayQuestions);
          },
        },
      });
      // 获取嵌入问卷元素
      setSurveyEle(surveyEle);
      getSurveyPanelRef.current = getRef;
    }
  }, [tuxSurveyConfig]);

  useEffect(() => {
    if (surveyPanelRef.current && surveyEle) {
      surveyPanelRef.current.replaceChildren(surveyEle);
    }
  }, [surveyEle]);

  const progressEle = useMemo(() => {
    // 必填的总数
    const total = displayQuestions.filter((c) => c.model.required).length;
    const count = displayQuestions.reduce((res: number, item) => {
      if (item.model.required) {
        const { type, value } = item;
        if (type === EQuestionItemType.Star || type === EQuestionItemType.Option) {
          if (value.length > 0) {
            return res + 1;
          }
        }
        if (type === EQuestionItemType.Text) {
          if (value.length !== 0 && value !== undefined) {
            return res + 1;
          }
        }
      }
      return res;
    }, 0);
    const percentage = (count / total) * 100;
    return (
      <Progress
        theme="plump"
        percentage={percentage}
        strokeWidth="3px"
        label={null}
        trackColor="#C2DAFF"
        color="#0A6CFF"
      />
    );
  }, [displayQuestions]);

  const submit = async () => {
    const validateResult = await getSurveyPanelRef.current?.()?.submit();
    if (validateResult === undefined) {
      MessagePlugin.warning('验证失败');
    }
    console.log('validateResult:', validateResult);
    const { validate } = validateResult ?? {};
    // 验证问题填答失败的情况
    if (!validate) {
      return false;
    }
    router.replace({
      name: 'SurveySuccessView',
    });
  };

  const wechatShareData = useMemo(() => {
    if (wechatIsReady) {
      const desc = displayQuestions?.[0]?.model?.title ?? '';
      return {
        title: `${legacy?.title ?? '用户调研'} - TUX`,
        desc,
        link: window.location.href,
        imgUrl: 'https://tux.qq.com/cos/tux_ui/static/img/const/tux_logo_v1.png',
        // 分享成功
        success: () => {

        },
      };
    }
    return undefined;
  }, [wechatIsReady, legacy?.title, displayQuestions]);
  useEffect(() => {
    if (wechatShareData) {
      wx?.updateAppMessageShareData?.(wechatShareData);
    }
  }, [wechatShareData]);

  return (
    <div className="question-previewer-panel h-100-p flex-columns">
      <div className="flex-none">
        <Row className="h-10px w-100-p" align="middle" style={{ background: '#F0F6FF' }}>
          <Col span={12} className="pl-24px pr-24px">
            {progressEle}
          </Col>
        </Row>
      </div>
      <div className="flex-1 pl-24px pr-24px pt-16px" style={{ overflowY: 'auto' }}>
        {
          !legacy.guide_config?.guide_page_switch && legacy.guide_config?.title_switch && <div
            className="survey-top-guide-content"
          >
            <SurveyGuideContent legacy={legacy} />
          </div>
        }
        <div ref={surveyPanelRef}></div>
        <div className="pt-16px pb-48px">
          <Row gutter={16}>
            {nextPageDisplayQuestions.length > 0 ? (
              <Col flex={1}>
                <div
                  className="submit"
                  onClick={async () => {
                    const pageIndex = await getSurveyPanelRef.current?.()?.nextPageIndex();
                    if (pageIndex !== undefined) {
                      setSurveyPageIndex(pageIndex);
                    }
                  }}
                >
                  <Row className="w-100-p h-100-p" justify="center" align="middle">
                    <Col className="c-fff fs-14px">下一页</Col>
                  </Row>
                </div>
              </Col>
            ) : null}
            {nextPageDisplayQuestions.length === 0 ? (
              <Col flex={1}>
                <div className="submit" onClick={submit}>
                  <Row className="w-100-p h-100-p" justify="center" align="middle">
                    <Col className="c-fff fs-14px">提交</Col>
                  </Row>
                </div>
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default QuestionPreviewer;
